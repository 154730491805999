import React from 'react';
import ImageUploading from 'react-images-uploading';
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import theme from '../../../theme';
import CountryAutoComplete from '../../CountryAutoComplete';
import LanguageAutoComplete from '../../LanguagesAutoComplete';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },

  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  html: {
    height: '100%',
    margin: 0,
  },
}));

const ProfileImageStep = ({
  userType,
  image,
  setImage,
  errors,
  setErrors,
  onFormChange,
  formData,
}) => {
  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
    onFormChange({ target: { name: 'profile_pic', value: imageList[0] } });
  };
  // we are already filtering the results with our API
  // this function returns all values in the list (doesnt filter any)
  const DONT_FILTER_RESULTS = (options, state) => options;



  const classes = useStyles(theme);
  return (
    <>
      {/* Candidat */}

      <Grid item xs={12}>
        <Typography variant="h2" className={classes.smallTitle}>
          {userType === 'Candidate' ? (
            <>
              Facilitez votre connexion professionnelle en ajoutant votre photo
              professionnelle.
            </>
          ) : (
            <>Ajoutez le logo de l'entreprise</>
          )}
        </Typography>

        <ImageUploading value={image} onChange={onChange} dataURLKey="data_url">
          {({ imageList, onImageUpload }) => (
            <div className="upload__image-wrapper">
              <Button
                className={classes.reverseButtonStyle}
                onClick={onImageUpload}
              >
                {userType === 'Candidate' ? (
                  <>Téléverser une photo</>
                ) : (
                  <>Téléverser un logo</>
                )}
              </Button>

              {imageList.map((imag, index) => (
                <div key={index} className="image-item">
                  <img src={imag.data_url} alt="" width="100" />
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
        {errors.profile_pic && (
          <p className={`${classes.error} ${'error'}`}>{errors.profile_pic}</p>
        )}
      </Grid>
    </>
  );
};

export default ProfileImageStep;
