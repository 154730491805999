import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader, Box, Fab, ListItem, ListItemText,
} from '@material-ui/core';
import theme from '../../theme';
import useSessionContext from "../../sessions/useSessionContext";
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/core/Link';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
    textAlign: 'center',
  },
  profileTabTitle: {

    '& span': {
      fontWeight: 600,
      fontSize: '1.928rem',
      textAlign: 'center',
    },
  },
  infoIcon: {
    alignSelf: 'flex-start',
    marginTop: '10px',
  },
  infoLink: {
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  player: {
    height: '100%',
    width: '100%',
  },
  modalCard: {
    marginTop: '5vw',
    aspectRatio: '16/9',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  linkEntreprise: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    textDecoration: 'none',
    padding: '0 20px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#e5087e',
      boxShadow: ' 2px 1px 1px 0 rgba(0, 0, 0, 0.1)',
    },
    '&:focus, &.focus': {
      color: '#ffffff',
      backgroundColor: '#e5087e',
      borderColor: '#ffffff',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
  },
}));


const HelpSection = (props) => {
  const classes = useStyles(theme);
  const activeStyle = { color: '#000' };
  const { user } = useSessionContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalSecond, setOpenModalSecond] = React.useState(false);


  const handleModalOpen = (event) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalOpenSecond = (event) => {
    event.preventDefault();
    setOpenModalSecond(true);
  };

  const handleModalCloseSecond = () => {
    setOpenModalSecond(false);
  };

  const modalBody = (
    <>
      <Card className={classes.modalCard}>
        <CardActionArea className={classes.player}>
          <CardMedia
            component="iframe"
            alt="Tutorial moovejob"
            width="100%"
            height="100%"
            image="https://www.youtube.com/embed/IjZ4mivU8to"
            autoPlay
            controls
            allowfullscreen="allowfullscreen"
            title="Tutorial moovejob"
          />
        </CardActionArea>
      </Card>
    </>
  );

  const modalBodyExplication = (
    <>
      <Card className={classes.modalCard}>
        <CardActionArea className={classes.player}>
          <CardMedia
            component="iframe"
            alt="Tutorial Explication"
            width="100%"
            height="100%"
            image="https://www.youtube.com/embed/y-9-SdfxRZg"
            autoPlay
            controls
            allowfullscreen="allowfullscreen"
            title="Tutorial Explication"
          />
        </CardActionArea>
      </Card>
    </>
  );

  return (
    <div id="abonnements">
      <Grid container>
        <Card square className={classes.rootCard} elevation={0}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <CardHeader
                title="Ressources d'aide"
                className={classes.profileTabTitle}
              />
            </Grid>
          </Grid>
        </Card>
        <Card className={classes.rootCard} square elevation={1}>
          <CardContent >
            <Box className={classes.infoIcon}>
              <Fab color="secondary" onClick={handleModalOpen}>
                <InfoIcon />
              </Fab>
              <Link
                href="#"
                onClick={handleModalOpen}
                className={classes.infoLink}
              >
                Comment optimiser votre profil !
              </Link>
            </Box>
            <Modal open={openModal} onClose={handleModalClose}>
              {modalBody}
            </Modal>
          </CardContent>
        </Card>
        <Card className={classes.rootCard} square elevation={1}>
          <CardContent >
            <Box className={classes.infoIcon}>
              <Fab color="secondary" onClick={handleModalOpenSecond}>
                <OndemandVideoIcon />
              </Fab>
              <Link
                href="#"
                onClick={handleModalOpenSecond}
                className={classes.infoLink}
              >
                Visualisez la démo enregistrée !
              </Link>
            </Box>
            <Modal open={openModalSecond} onClose={handleModalCloseSecond}>
              {modalBodyExplication}
            </Modal>
          </CardContent>
        </Card>
        <Card className={classes.rootCard} square elevation={1}>
          <CardContent>
            { user.type_of_user === "Enterprise" && (
            <>
              <ListItem
                style={{
                  width: 'auto',
                  paddingTop: 0,
                  paddingBottom: 0,
                  justifyContent: 'center',
                }}
              >
                <a
                  href={"https://calendly.com/moovejob/demo"}
                  activeStyle={activeStyle}
                  className={classes.linkEntreprise}
                  target= "_blank"
                >
                  <ListItemText>Demander une Démo</ListItemText>
                </a>
              </ListItem>
            </>
            )}
            { user.type_of_user === "Partner" && (
              <>
                <ListItem
                  style={{
                    width: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    justifyContent: 'center',
                  }}
                >
                  <a
                    href={"https://calendly.com/moovejob/demo"}
                    activeStyle={activeStyle}
                    className={classes.linkEntreprise}
                    target= "_blank"
                  >
                    <ListItemText>Demander une Démo</ListItemText>
                  </a>
                </ListItem>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default HelpSection;
