import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    padding: '16px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  fitRight: {
    marginRight: '5%',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
    },
  },
  typography: {
    margin: '16px 0 10px',
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
  },
  submitButtonRow: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
    },
  },
}));

const PersonalityTest = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { informError, informSuccess } = SnackBar;
  const [formData, setFormData] = useState({
    linkToResult: user.test_result_link || '',
    portfolioLink: user.portfolio_link || '',
    linkedInLink: user.linkedin_link || '',
    presentationLink: user.presentation_link || '',
  });

  const handleSubmit = () => {
    const raw = {
      user: {
        linkToResult: formData.linkToResult,
        portfolioLink: formData.portfolioLink,
        linkedInLink: formData.linkedInLink,
        presentationLink: formData.presentationLink,
      },
    };
    updateUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          refreshUserData();
          informSuccess('Succès!');
        } else {
          informError('Une erreur est survenue');
        }
      }
    });
  };

  const onFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            1. Réalisez votre test de personnalité à cette addresse
          </Typography>
          <a
            href="https://www.16personalities.com/fr/test-de-personnalite"
            target="_blank"
            rel="noreferrer"
          >
            https://www.16personalities.com/fr/test-de-personnalite
          </a>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            2. Collez votre résultat ci-dessous
          </Typography>
          <TextField
            className={classes.fitRight}
            type="url"
            id="outlined"
            pattern="^https?://(www\.)?16personalities\.com"
            required
            label="Collez votre résultat ici"
            variant="outlined"
            name="linkToResult"
            value={formData.linkToResult || ''}
            placeholder="Collez votre résultat ici"
            onChange={(e) => onFormChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Collez le lien vers votre profil LinkedIn ci-dessous
          </Typography>
          <TextField
            className={classes.fitRight}
            type="url"
            id="outlined"
            pattern="^https?://(www\.)?linkedin\.com"
            required
            label="Collez le lien vers votre profil LinkedIn ici"
            variant="outlined"
            name="linkedInLink"
            value={formData.linkedInLink || ''}
            placeholder="Collez le lien vers votre profil LinkedIn ici"
            onChange={(e) => onFormChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Collez le lien de votre site web / portfolio en ligne ci-dessous
          </Typography>
          <TextField
            className={classes.fitRight}
            type="url"
            id="outlined"
            required
            label="Collez le lien de votre site web / portfolio en ligne ici"
            variant="outlined"
            name="portfolioLink"
            value={formData.portfolioLink || ''}
            placeholder="Collez le lien de votre site web / portfolio en ligne ci-dessous"
            onChange={(e) => onFormChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Collez le lien vers une vidéo de présentation de vous ci-dessous
          </Typography>
          <TextField
            className={classes.fitRight}
            type="url"
            id="outlined"
            pattern="^https?://(www\.)?youtube\.com"
            required
            label="Collez le lien vers une vidéo de présentation de vous ici"
            variant="outlined"
            name="presentationLink"
            value={formData.presentationLink || ''}
            placeholder="Collez le lien vers une vidéo de présentation de vous ici"
            onChange={(e) => onFormChange(e)}
          />
        </Grid>
      </Grid>
      <br/>
      <Grid container item justifyContent="center">
        <Grid item xs={12} className={classes.submitButtonRow}>
          <Button onClick={handleSubmit}>Sauvegarder</Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.typography}>
        <Typography>* Aucune case n'est obligatoire</Typography>
      </Grid>
    </Grid>
  );
};

export default PersonalityTest;
