import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getMetiersSearch } from '../Functions/Get-query/getMetiersSearch';
import { getMetiersSearchByWorkType } from '../Functions/Get-query/getMetiersSearchByWorkType';

const useStyles = makeStyles(() => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const MetiersAutoComplete = ({
  name,
  onFormChange,
  limit,
  multiple,
  formData,
  errors,
  userType
}) => {
  const [isOpen, setOpen] = useState(false);
  const [metiersList, setMetiersList] = useState([]);
  const [ metiersData, setMetiersData ] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setMetiersList([]);
    }
  }, [isOpen]);

  const onTextChanged = (event) => {
    setMetiersList([]);
    const query = event.target.value;
    if (query === null) {
      return;
    }
    setLoading(true);
    if(formData.byWorkType && formData.workType!=null){
      getMetiersSearchByWorkType(formData.workType.id,query).then((response) => {
        if (response !== null && response !== undefined) {
          if (response.total > 0) {
            setMetiersList(response.results);
          }
          setLoading(false);
        } else {
          setMetiersList([]);
          setLoading(true);
          console.error("Une erreur est survenue" + response)
        }
      });
    }else if(!formData.byWorkType){
      getMetiersSearch(query).then((response) => {
        if (response !== null && response !== undefined) {
          if (response.total > 0) {
            setMetiersList(response.results);
          }
          setLoading(false);
        } else {
          setMetiersList([]);
          setLoading(true);
          console.error("Une erreur est survenue" + response)
        }
      });
    }
    
  };

  const handleMetierSelection = (event, data) => {
    if (data === null) {
      return onFormChange({
        target: {
          name,
          value: [],
        },
      });
    }

    const jobsArray = data.map((d) => ({
      id: d.id,
      name: d.name,
    }));
    setMetiersData(data)

    onFormChange({
      target: {
        name: 'jobs',
        value: jobsArray,
      },
    });
  };
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        disabled={formData.byWorkType && formData.workType==null && metiersList!=null && metiersList.length==0}
        noOptionsText="En chargement..."
        multiple={multiple}
        onOpen={onTextChanged}
        onClose={() => setMetiersList([])}
        limitTags={limit}
        name="jobs"
        id="jobs"
        loading={isLoading}
        options={metiersList}
        getOptionLabel={(option) => (userType === 'Enterprise' ? option.title : option.name)}
        getOptionDisabled={() => metiersData.length >= limit ? true : false}
        getOptionSelected={(option, value) => option.id === value.id}
        key={(option) => option.id}
        onChange={handleMetierSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onTextChanged}
            onBlur={onTextChanged}
            variant="outlined"
            error={!!errors.jobs}
            label={formData.byWorkType ? "Liste des métiers correspondants" : "Appelation d'emploi (en majuscules sans accents)"}
            placeholder="Emploi"
          />
        )}
      />
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

export default MetiersAutoComplete;
