import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Nationalities from '../Nationality';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    padding: '16px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  fitRight: {
    marginRight: '5%',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
    },
  },
  typography: {
    margin: '16px 0 10px',
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
  },
  submitButton: {
    marginTop: '20px!important',
  },
  submitButtonRow: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
    },
  },
}));

const PersonalInformation = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { informError, informSuccess } = SnackBar;
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [formData, setFormData] = useState({
    numberOfChildren: user.numberOfChildren || 0,
    maritalStatusId: 1,
    availability: user.availability || '',
  });
  const [driverLicense, setDriverLicense] = useState({
    moto: user.driverLicense.moto ?? false,
    auto: user.driverLicense.auto ?? false,
    camion: user.driverLicense.camion ?? false,
  });

  const countriesList = Nationalities;
  const userCountries = user.userNationalities
    ? countriesList.filter((country) =>
        user.userNationalities.includes(country.name),
      )
    : [];
  const [nationalities, setNationalities] = useState(userCountries);

  const onNationalitiesChange = (e, value) => {
    setNationalities(value);
  };

  const onDriverLicenseChange = ({ target }) => {
    const { name, checked } = target;
    setDriverLicense((driverLicense) => ({
      ...driverLicense,
      [name]: checked,
    }));
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    if (!(name === 'numberOfChildren' && value < 0)) {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    const userNationalities = [];
    nationalities.map((nationality) => {
      userNationalities.push(nationality.name);
    });
    const raw = {
      user: {
        number_of_children: formData.numberOfChildren,
        marital_status_id: formData.maritalStatusId,
        availability: formData.availability,
        driver_license: driverLicense,
        nationalities_name: userNationalities,
      },
    };
    updateUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          refreshUserData();
          informSuccess('Succès!');
        } else {
          informError('Une erreur est survenue');
        }
      }
    });
  };

  const fetchMaritalStatusList = () => {
    fetch('/get_marital_status_list')
      .then((response) => response.json())
      .then((data) => {
        setMaritalStatusList(data);
        setFormData((formData) => ({
          ...formData,
          maritalStatusId: user.maritalStatus.id || 1,
        }));
      })
      .catch(() => informError('Une erreur est survenue'));
  };

  useEffect(() => {
    fetchMaritalStatusList();
  }, []);

  const renderMaritalStatusList = maritalStatusList.map((maritalStatus) => (
    <MenuItem
      key={maritalStatus.id}
      value={maritalStatus.id}
      label={maritalStatus.name}
    >
      {maritalStatus.name}
    </MenuItem>
  ));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Nombre d'enfants
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <TextField
            className={classes.fitRight}
            id="number-of-children"
            label={formData.numberOfChildren < 0 ? 'Min. 0' : ''}
            variant="outlined"
            type="number"
            error={formData.numberOfChildren < 0}
            value={formData.numberOfChildren}
            name="numberOfChildren"
            onChange={onFormChange}
            inputProps={{
              min: 0,
            }}
          />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            État civil
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <TextField
            className={classes.fitRight}
            id="marital-status"
            variant="outlined"
            select
            value={formData.maritalStatusId}
            name="maritalStatusId"
            onChange={onFormChange}
          >
            {renderMaritalStatusList}
          </TextField>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Disponibilité (Date a laquelle vous seriez prêt a déménager /
            immigrer)
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <TextField
            className={classes.fitRight}
            id="availability"
            type="date"
            variant="outlined"
            value={formData.availability}
            name="availability"
            onChange={onFormChange}
          />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Permis de conduire
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={driverLicense.moto}
                  onChange={onDriverLicenseChange}
                  name="moto"
                />
              }
              label="Moto"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={driverLicense.auto}
                  onChange={onDriverLicenseChange}
                  name="auto"
                />
              }
              label="Auto"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={driverLicense.camion}
                  onChange={onDriverLicenseChange}
                  name="camion"
                />
              }
              label="Camion"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            Nationalités
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <Autocomplete
            className={classes.fitRight}
            multiple
            id="nationalities"
            options={countriesList}
            getOptionLabel={(option) => option.name}
            value={nationalities}
            onChange={onNationalitiesChange}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid item xs={12} sm={6} className={classes.submitButtonRow}>
          <Button className={classes.submitButton} onClick={handleSubmit}>
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInformation;
