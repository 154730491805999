import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import useSessionContext from '../../sessions/useSessionContext';
import Pagination from '@material-ui/lab/Pagination';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Fab, Card, CardHeader, Button, Chip, ListItemSecondaryAction, Input, InputAdornment } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import { apiUrl } from '../../Functions/apiUrl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import JobModal from './FormModal/JobModal';
import ViewCv from '../ViewCurriculumModal';
import { useHistory } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
    textAlign: 'left',
  },
  messageTabTitle: {
    '& span': {
      fontWeight: 600,
    },
  },
  gridRoot: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  listConv: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #eee",
    '& ul': {
      padding: '0'
    },
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  messageInputContainer: {
    padding: theme.spacing(2),
  },
  messageInput: {
    marginRight: theme.spacing(2),
  },
  avatar: {},
  unreadListItem: {
    backgroundColor: '#f0f0f0',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  readListItem: {
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  conversationContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: '80vh',
    overflow: 'hidden',
    '& li': {
      listStyle: 'none'
    },
  },
  messagesList: {
    overflowY: 'auto',
    flexGrow: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    margin: '10px',
    padding: '10px',
    '& .MuiOutlinedInput-root': {
      border: 'none!important'
    },
    '& .MuiInput-underline:before': {
      content: 'none'
    },
  },
  primaryText: {
    fontSize: '1rem',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontSize: '0.875rem',
  },
  messageBubble: {
    maxWidth: '75%',
    padding: theme.spacing(1),
    borderRadius: '20px',
    margin: theme.spacing(1, 0),
    wordBreak: 'break-word',
    '& p': {
      marginBottom: '0',
      fontWeight: 'normal'
    },
  },
  messageReceived: {
    backgroundColor: '#f4f4f4',
    marginRight: 'auto',
  },
  messageSent: {

    backgroundColor: '#4fc3f7',
    color: "white",
    marginLeft: 'auto',
  },
  messageSentDate: {
    textAlign: 'right',
    '& span': {
      fontSize: '0.8rem',
      fontWeight: 500
    },
  },
  messageReceivedDate: {
    textAlign: 'left',
    '& span': {
      fontSize: '0.8rem',
      fontWeight: 500
    },

  },
  unreadBadge: {
    height: '10px',
    width: '10px',
    backgroundColor: 'red',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  selectedListItem: {
    backgroundColor: '#e5087e',
    border: "1px solid #e5087e",
    color: "white"
  },
  reverseButtonStyle: {
    color: '#ffffff',
    backgroundColor: 'secondary',

    '&:hover': {
      backgroundColor: 'secondary',
    },
    '&:focus, &.focus': {
      backgroundColor: 'secondary',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  subscribePanel: {
    width: 'auto'
  },
  paginationContainer: {
    margin: '20px 0px',
    marginBottom: '40px',
    '& ul': {
      justifyContent: 'center'
    },
    '& button': {
      boxShadow: 'none'
    },
  },
  chipLabel:{
    fontWeight:700
  },
  listItemSecondary: {
    display: 'flex',
    flexDirection: 'row',
    top: '25px',
    columnGap: '10px',
  },
  listItem: {
    borderBottom: '1px solid #ebebeb',
    paddingBottom: '3vh',
    display: 'block',
  },
  backButton:{
    cursor: 'pointer'
  }
}));

export default function Messages() {
  const classes = useStyles();
  const [groupedMessages, setGroupedMessages] = useState({});
  const { getMessages, user, SnackBar, setNavId, refreshUserData } = useSessionContext();
  const {
    informError
  } = SnackBar;
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(5);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [selectedConversationKey, setSelectedConversationKey] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState([]);
  const [showConversation, setShowConversation] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [metierData, setMetierData] = useState({});
  const [cvData, setCvData] = useState({});
  const [showViewMetierModal, setShowViewMetierModal] = useState(false);
  const [showViewCvModal, setShowViewCvModal] = useState(false);
  const closeViewEditModal = () => setShowViewMetierModal(false);
  const closeViewCvModal = () => setShowViewCvModal(false);
  const { is_subscribed, type_of_user } = user;
  const [state, setState] = useState({ right: false });
  const history = useHistory();
  const [loadMessages, setLoadMessages] = useState(false);

  useEffect(() => {
    const loadMessages = async () => {
      const messages = await getMessages();
      setGroupedMessages(messages);
    };
    loadMessages();
  }, [currentPage, getMessages]);

  useEffect(() => {
    const intervalId = setInterval(()=>{
      refreshMessages();
    }, 25000);
    return () => clearInterval(intervalId);
  }, [refreshMessages]);

  useEffect(() => {
    loadCurrentMessages();
  }, [currentPage, groupedMessages, loadCurrentMessages]);

  useEffect(() => {
    const scrollContainer = document.getElementById('messageList');
    if (scrollContainer) {
      setTimeout(() => {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 300);
    }
  }, [selectedConversation]);

  const loadCurrentMessages = useCallback(() => {
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    setCurrentMessages(Object.entries(groupedMessages).slice(indexOfFirstMessage, indexOfLastMessage));
  }, [currentPage, groupedMessages, messagesPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleListItemClick = useCallback((conversationKey) => {
    setLoadMessages(true);
    setSelectedConversationKey(conversationKey);
    const selectedConv = groupedMessages[conversationKey] || { messages: [] };
    setSelectedConversation(selectedConv.messages);
    setTimeout(() => {
      const scrollContainer = document.getElementById('messageList');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }, 100);
    setShowConversation(true);
    readConversation(selectedConv.messages,conversationKey);
    setLoadMessages(false);
    setTextMessage('');
  }, [groupedMessages]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric'};
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatDateTime = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const refreshMessages = useCallback(async () => {
    const messages = await getMessages();
    setGroupedMessages(messages);
  }, [getMessages, selectedConversationKey, selectedConversation]);

  const sendMessage = () => {
    if (type_of_user === 'Candidate') {
      if (textMessage === '') {
        return;
      }
    } else {
      const iSubscribed = selectedConversation[0].is_subscribed;
      if (type_of_user !== 'Candidate' && !iSubscribed) {
        informError('Vous devez vous abonner a moovejob pour envoyer des messages');
        return;
      }
    }
    setTextMessage('');
      const message = Object.assign({}, selectedConversation[0])
      const selectedConv = groupedMessages[selectedConversationKey] || { messages: [] };
      message.customize_message = textMessage;
      message.sender_id = user.id;
      message.receiver_id = message.contact_id;
      message.send_by_me = true;
      message.created_at = new Date();
      const currentConv = selectedConv.messages;
      currentConv.push(message);
      setSelectedConversation(currentConv);
      axios.post(`${apiUrl()}/sendMessage`, {
        message: textMessage,
        type_of_user: type_of_user,
        metier_id: message.metier_id,
        partner_id: message.partner_id,
        candidate_id: message.candidate_id,
        enterprise_id: message.enterprise_id,
        applicant_id: message.applicant_id,
        provider_id: message.provider_id,
        service_id: message.notification_service_id,
        sender_id: user.id,
        receiver_id: message.contact_id
      })
      .then(async (response) => {
        if (response.status === 200) {
          setCurrentPage(1);
          refreshMessages();
        }
      })
      .catch((error) => errorHandling(error));
  };

  const readConversation = (messages,groupKey) => {
    let idConv = messages.filter((x)=>x.is_read==false && x.receiver_id==user.id).map((message) => {
      return message.id;
    });
    if(idConv.length>0){
      axios.post(`${apiUrl()}/readConv`, {
        conversations: idConv
      })
      .then((response) =>{
        /*const updatedMessages = [...currentMessages ];
        updatedMessages.forEach(function (item,idx) {
          if(item[0]==groupKey){
            updatedMessages[idx][1].unread_count = 0;
          }
        });*/
        setCurrentMessages(updatedMessages);
      })
      .catch(error => {
          console.error(error);
      });
    }

  }

  const handleTextMessage = (e) => {
    setTextMessage(e.target.value);
  };

  const handleOpenViewCVModal = () => () => {
    setCvData({
      metierId: selectedConversation[0].metier_id,
      userMetierId: selectedConversation[0].user_metier,
      metierName: selectedConversation[0].metier,
    });

    setShowViewCvModal(true);
  };

  const handleOpenViewMetierModal = (metier) => () => {
    setMetierData(metier);
    setShowViewMetierModal(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  function navigate(path) {
    history.push(path);
  }

  const navigateToSubscription = () => {
    toggleDrawer();
    navigate('/mon-compte');
    setNavId(5);
  };

  return (
    <Grid container>
      <Card square className={classes.rootCard} elevation={0}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <CardHeader
              title="Messages"
              className={classes.messageTabTitle}
            />
          </Grid>
        </Grid>
      </Card>

      <Card className={classes.rootCard} square elevation={1}>
        <CardContent>
        {(type_of_user != 'Candidate' && !is_subscribed) ? (
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" display="block">
                Vous devez vous abonner pour accéder à la messagerie
              </Typography>
              <div classes={classes.subscribePanel}>
                <Button
                  onClick={navigateToSubscription}
                  className={classes.reverseButtonStyle}
                  color="primary"
                >
                  Vous Abonner
                </Button>
              </div>
            </Grid>
          </Grid>
        ) : null}
        {currentMessages.length> 0 && (type_of_user === 'Candidate' || is_subscribed) ? (

          <>
            {!loadMessages ? (
              <>
                <Grid container alignItems="stretch">
                  {!showConversation ? (
                    <>
                      <Grid item xs={12} md={12}>

                      <div className={classes.listConv}>
                        <List>
                          {currentMessages.map(([groupKey, details]) => (
                            <ListItem
                              key={groupKey}
                              className={details.unread_count > 0 ? classes.unreadListItem : classes.readListItem}
                              button
                              selected={selectedConversationKey === groupKey}
                              onClick={() => handleListItemClick(groupKey)}

                            >
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography variant="body2">{formatDate(typeof details.messages != "undefined" && details.messages[details.messages.length - 1].created_at)}</Typography>
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography variant="subtitle1" color={details.unread_count == 0 ? 'textPrimary' : 'secondary'}><b>{details.messages[0].contact_name}</b></Typography>
                                    <Typography variant="subtitle1" color={details.unread_count == 0 ? 'textPrimary' : 'secondary'}>{details.messages[details.messages.length - 1].customize_message.length > 40 ? `${details.messages[details.messages.length - 1].customize_message.substring(0, 40)}` : details.messages[details.messages.length - 1].customize_message}</Typography>
                                    <Chip
                                        className={classes.chipLabel}
                                        icon={<LabelIcon />}
                                        label={details.messages[0].contact_type}
                                        size='small'
                                        color='secondary'
                                        variant='outlined'
                                    />
                                  </React.Fragment>
                                }
                                primaryTypographyProps={{ className: classes.primaryText }}
                                secondaryTypographyProps={{ component: "div", className: classes.ellipsis }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>

                      </Grid>
                      <Grid item lg={12} className={classes.paginationContainer}>
                      <Pagination
                        count={Math.ceil(Object.keys(groupedMessages).length / messagesPerPage)} page={currentPage}
                        siblingCount={0}
                        onChange={handlePageChange}
                        color="secondary"
                        size="large"
                      />
                      </Grid>
                    </>
                  ) : null}
                  {showConversation ? (
                    <Grid item xs={12} md={12}>
                      <Fab 
                        variant="extended" 
                        size="medium"
                        color="secondary"
                        title="back"
                        onClick={() => setShowConversation(false)}
                      >
                        <ArrowBackIosIcon className={classes.backButton}/>
                        Retour
                      </Fab>
                      <div className={classes.conversationContainer}>

                        {selectedConversation.length > 0 && (
                            <>
                              {selectedConversation[0]?.metier && (
                                <ListItem
                                  key={`notification-${selectedConversation[0].id}`}
                                  dense
                                  className={classes.listItem}
                                >
                                  <div
                                    style={{
                                      display: 'block',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <ListItemText
                                      primary={selectedConversation[0]?.contact_name}
                                      secondary={selectedConversation[0]?.metier}
                                    />
                                    <ListItemText
                                      primary= {selectedConversation[0]?.contact_email}
                                    />
                                    <Chip
                                          className={classes.chipLabel}
                                          icon={<LabelIcon />}
                                          label={selectedConversation[0]?.contact_type}
                                          size='small'
                                          color='secondary'
                                          variant='outlined'
                                      />
                                  </div>

                                  {/* <ListItemSecondaryAction className={classes.listItemSecondary}>
                                    {type_of_user == "Candidat"  || (type_of_user == "Enterprise"  && selectedConversation[0]?.contact_type === "Candidat")  ? (
                                          <Fab
                                            color="secondary"
                                            aria-label="add"
                                            onClick={handleOpenViewMetierModal({
                                              metierId: selectedConversation[0].metier_id,
                                              userMetierId: selectedConversation[0].user_metier,
                                              metierName: selectedConversation[0].metier,
                                            })}
                                          >
                                            <VisibilityIcon />
                                          </Fab>
                                      ) : null}
                                      {type_of_user == "Enterprise"  && selectedConversation[0]?.contact_type === "Entreprise"  ? (
                                          <Fab
                                            color="secondary"
                                            aria-label="add"
                                            disabled={true}
                                          >
                                            <VisibilityIcon />
                                          </Fab>
                                      ) : null}
                                      {type_of_user == "Enterprise" && selectedConversation[0]?.contact_type!== "Entreprise" ? (
                                          <Fab
                                            color="secondary"
                                            aria-label="view"
                                            onClick={handleOpenViewCVModal()}
                                          >
                                            <DescriptionIcon />
                                          </Fab>
                                      ) : null}
                                  </ListItemSecondaryAction> */}
                                </ListItem>
                              )}
                              {selectedConversation[0]?.service && (
                                <ListItem
                                  key={`notification-${selectedConversation[0].id}`}
                                  dense
                                  className={classes.listItem}
                                >
                                  <div
                                    style={{
                                      display: 'block',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <ListItemText
                                      primary={selectedConversation[0]?.contact_name}
                                      secondary={selectedConversation[0]?.service}
                                    />
                                    <ListItemText
                                      primary= {selectedConversation[0]?.contact_email}
                                    />
                                    <Chip
                                          className={classes.chipLabel}
                                          icon={<LabelIcon />}
                                          label={selectedConversation[0]?.contact_type}
                                          size='small'
                                          color='secondary'
                                          variant='outlined'
                                      />
                                  </div>
                                </ListItem>
                              )}
                              <div id="messageList" className={classes.messagesList}>
                                {selectedConversation.map((message, index) => (
                                  <div key={index} >
                                    <div className={`${message.send_by_me ? classes.messageSentDate : classes.messageReceivedDate}`}>
                                      <Typography variant="caption" display="block" gutterBottom>
                                        {formatDateTime(message.created_at)}
                                      </Typography>
                                    </div>
                                    <div key={index} className={`${classes.messageBubble} ${message.send_by_me ? classes.messageSent : classes.messageReceived}`}>
                                      <Typography paragraph>
                                        {message.customize_message}
                                      </Typography>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                        )}

                        {selectedConversation.length > 0 && (
                            <>
                              
                            </>
                        )}
                        
                        {selectedConversation.length > 0 && ((selectedConversation[0]?.is_contact_subscribed != null && selectedConversation[0]?.is_contact_subscribed)) ? (
                          <div className={classes.inputContainer}>
                            <Input
                                variant="outlined"
                                fullWidth
                                multiline
                                value={textMessage}
                                label="Votre message..."
                                placeholder="Votre message..."
                                onChange={handleTextMessage}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <SendIcon style={{
                                      cursor: 'pointer',
                                    }} onClick={sendMessage}/>
                                  </InputAdornment>
                                }
                              />
                          </div>
                        ) : null}

                        {selectedConversation.length > 0 && ((selectedConversation[0]?.is_contact_subscribed == null  || !selectedConversation[0]?.is_contact_subscribed)) ? (
                            <div className={classes.inputContainer}>
                              <Typography paragraph color='secondary'>
                                Vous ne pouvez pas communiquer avec cet interlocuteur il n'est pas encore abonné à la plateforme
                              </Typography>
                            </div>
                        ) : null}
                      </div>
                    </Grid>
                  ) : null}
                  
                </Grid>
                {showViewMetierModal && (
                  <JobModal
                    closeModal={closeViewEditModal}
                    metierData={metierData}
                    show={showViewMetierModal}
                    ViewMetier={true}
                  />
                )}
                {showViewCvModal && (
                  <ViewCv
                    closeModal={closeViewCvModal}
                    cvData={cvData}
                    show={showViewCvModal}
                    viewCv
                  />
                )}
              </>
            ) :
              (
                <Grid container>
                  <Grid item lg={12}>
                    <Skeleton variant="rect" height={100} animation="wave" />
                  </Grid>
                </Grid>
              )}
          </>

        ) : null}
        { currentMessages.length == 0 && is_subscribed  ? (
          <>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1" display="block">
                  Pour commencer, envoyer un message à une de vos matchs à partir des notifications.
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
}
