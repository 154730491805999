import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  AppBar,
  Tab,
  Tabs,
  Box,
  Hidden,
  Fab,
  CardContent,
  Button,
  Select, FormControl, MenuItem, FormHelperText,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import ModifyInfo from './FormModal/modifyInfo';
import ModifyAvatar from './FormModal/ModifyAvatar';
import Destinations from './Destinations';
import Langues from './shared/Langues';
import Services from './Partner/Services';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import { TabPanel } from './TabPanel';
import Metiers from './Metiers';
import AlertComponent from '../AlertComponent';
import Banner from '../DisplayBannerButton';
import Modal from '@material-ui/core/Modal';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Contracts from './Contracts';
import InfoIcon from '@material-ui/icons/Info';
import ServicesSelection from './ServicesSelection';
import UploadCV from '../../Form/FormSteps/Shared/UploadCV';
import PersonalInformation from './PersonalInformation';

const getLocationDisplayName = (worldCity) => {
  if (worldCity) {
    return `${worldCity.city}, ${worldCity.country}`;
  }
  return 'Une ville, Un pays';
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& span': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
  },

  profilTypo: {
    textAlign: 'left',
  },
  profilCard: {
    padding: '15px',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      '&:first-of-type': {
        order: 0,
        alignItems: 'flex-start',
        paddingLeft: 20,
      },
      '&:nth-of-type(2)': {
        order: 2,
        padding: 20,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-of-type(2)': {
        order: 3,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    '& a': {
      color: '#000!important',
    },
  },
  divisionAlign: {
    alignSelf: 'flex-start',
  },
}))

const EmployeeCount = () => {
  const {
    user,
    updateUser,
    refreshUserData
  } = useSessionContext();
  const [employeeCount, setEmployeeCount]= useState(user.employee_count);
  const classes = useStyles(theme);

  const saveEmployeeCounter = () => {
    if (employeeCount != null && employeeCount != "") {
      const raw = {
        user: {
          employee_count: employeeCount,
        },
      };

      updateUser(raw)
        .then((response) => {
          if (response.status === 400) {
            if (response.data.error) {
              console.log('error saving count');
            }
          }
          if (response.status === 200) {
            refreshUserData();
          }
        });
    }
  };


  const handleChange = (e) => {
    const selectedCount = e.target.value;
    setEmployeeCount(selectedCount );
  }

  useEffect(()=>{
      saveEmployeeCounter()
    }
    ,[employeeCount])
if (user.type_of_user === 'Enterprise') {
  return (
    <Grid className={classes.divisionAlign}>
      <Typography>Nombre d'employés:</Typography>
      <Box>
        <FormControl>
          <Select
            value={employeeCount}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Veuillez Choisir
            </MenuItem>
            <MenuItem value={'Moins de 10 employés'}>Moins de 10 employés</MenuItem>
            <MenuItem value={'10 à 49 employés'}>10 à 49 employés</MenuItem>
            <MenuItem value={'50 à 249 employés'}>50 à 249 employés</MenuItem>
            <MenuItem value={'250 à 499 employés'}>250 à 499 employés</MenuItem>
            <MenuItem value={'Plus de 500 employés'}>Plus de 500 employés</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
}else {
  return null;
}
};

export default EmployeeCount;
