import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
  },
  fitRight: {
    maxWidth: '90%',
    marginRight: '5%',
  },
  typography: {
    margin: 16,
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  formLabel: {
    width: '100%',
    marginLeft: 0,
  },
  buttonRow: {
    padding: '10px 0 5px',
  },
  buttonBox: {
    margin: '0 auto',
  },
}));

const ListOfValues = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { userValues } = user;
  const { informError, informSuccess } = SnackBar;
  const [valuesList, setValuesList] = useState({});
  const [countValues, setCountValues] = useState(userValues.length ?? 0);

  const titleMessage = {
    Candidate:
      'Choisissez dans la liste déroulante les 7 valeurs qui vous ressemblent et touchent le plus.',
    Enterprise:
      'Choisissez dans la liste déroulante les 7 valeurs qui ressemblent le plus à votre culture d’entreprise',
  };

  const handleChange = ({ target }) => {
    const { name, checked } = target;
    if (countValues === 7 && !valuesList[name]) {
      informError('Vous avez déjà choisi 7 valeurs');
    } else {
      if (valuesList[name]) {
        if (countValues > 0) {
          setCountValues((countValues) => countValues - 1);
        }
      } else {
        setCountValues((countValues) => countValues + 1);
      }
      setValuesList((valuesList) => ({
        ...valuesList,
        [name]: checked,
      }));
    }
  };

  const handleSubmit = () => {
    const userValuesArray = [];
    Object.entries(valuesList).map(([key, value]) => {
      if (value) {
        userValuesArray.push(key);
      }
    });
    const raw = {
      user: {
        user_values: userValuesArray,
      },
    };
    updateUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          refreshUserData();
          informSuccess('Succès!');
        } else {
          informError('Une erreur est survenue');
        }
      }
    });
  };

  const fetchValuesList = () => {
    fetch('/get_list_of_values')
      .then((response) => response.json())
      .then((data) => {
        userValues.forEach((value) => {
          data[value] = true;
        });
        setValuesList(data);
      })
      .catch(() => informError('Une erreur est survenue'));
  };
  useEffect(() => {
    fetchValuesList();
  }, []);

  const renderValuesList = Object.entries(valuesList).map(([key, value]) => (
    <FormControlLabel
      className={classes.formLabel}
      key={key}
      control={<Checkbox checked={value} onChange={handleChange} name={key} />}
      label={key}
    />
  ));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.typography}>
          {titleMessage[user.type_of_user]}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.alignRight}>
        <FormGroup row>{renderValuesList}</FormGroup>
      </Grid>

      <Grid
        container
        item
        justifyContent="flex-start"
        className={classes.buttonRow}
      >
        <Grid item xs={6} className={classes.buttonBox}>
          <Button className={classes.fitRight} onClick={handleSubmit}>
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListOfValues;
