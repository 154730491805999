import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Card, CardHeader, Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CountryModal from './FormModal/CountryModal';
import MorePopover from './MorePopover';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
}));

const Destinations = (props) => {
  const { locationInfo, typeOfUser } = props;
  const [editMode, setEditMode] = useState('Edit');
  const [show, setShow] = useState(false);
  const [editedDestination, setEditedDestination] = useState('');

  const { user, updateUser, refreshUserData } = useSessionContext();

  const deleteDestination = (dest) => {
    const updatedDests = [];

    if (user.type_of_user === 'Candidate') {
      user.candidate_destinations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }
    if (user.type_of_user === 'Enterprise') {
      user.job_locations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }
    if (user.type_of_user === 'Partner') {
      user.partner_locations.forEach((location) => {
        if (location.id !== dest.id) {
          updatedDests.push(location.id);
        }
      });
    }

    const raw = {
      user: {
        destination_to_delete: dest.id,
        destinations: updatedDests,
        delete_destination: true,
      },
    };

    updateUser(raw).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.log('error deleting destination');
        }
      }
      if (response.status === 200) {
        refreshUserData();
      }
    });
  };

  const openCreateModal = () => {
    setEditMode('Add');
    setEditedDestination('');
    setShow(true);
  };

  const closeModal = () => setShow(false);
  const classes = useStyles(theme);

  const displayDestinations = () => {
    if (user.type_of_user === 'Candidate') {
      return user.candidate_destinations.map((dest) => (
        <Card className={classes.rootCard} square elevation={1}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              {dest.city ? (
                <CardHeader title={dest.city} />
              ) : (
                <CardHeader title={dest.country_fr} />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Fab
                id={`deleteDestination-${dest.id}`}
                aria-label="Delete"
                onClick={() => deleteDestination(dest)}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
        </Card>
      ));
    }
    if (user.type_of_user === 'Enterprise') {
      return user.job_locations.map((dest) => (
        <Card className={classes.rootCard} square elevation={1}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              {dest.city ? (
                <CardHeader title={dest.city} />
              ) : (
                <CardHeader title={dest.country_fr} />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Fab
                id={`deleteDestination-${dest.id}`}
                aria-label="Delete"
                onClick={() => deleteDestination(dest)}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
        </Card>
      ));
    }
    if (user.type_of_user === 'Partner') {
      return user.partner_locations.map((dest) => (
        <Card className={classes.rootCard} square elevation={1}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              {dest.city ? (
                <CardHeader title={dest.city} />
              ) : (
                <CardHeader title={dest.country_fr} />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Fab
                id={`deleteDestination-${dest.id}`}
                aria-label="Delete"
                onClick={() => deleteDestination(dest)}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
        </Card>
      ));
    }
    return null;
  };

  const locations =
    user.type_of_user === 'Candidate'
      ? user.candidate_destinations
      : user.job_locations || user.partner_locations;

  return (
    <Grid container>
      <Card square className={classes.rootCard} square elevation={0}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            {typeOfUser === 'Candidate' ? (
              <CardHeader
                title="Destinations choisies"
                className={classes.profileTabTitle}
              />
            ) : (
              <CardHeader
                title="Localisation(s) de l'entreprise"
                className={classes.profileTabTitle}
              />
            )}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 0',
            }}
          >
            <Fab
              id="addDestination"
              color="secondary"
              aria-label="add"
              onClick={() => {
                refreshUserData();
                openCreateModal();
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Card>
      {/* debut de la loop destination */}
      {displayDestinations()}

      <CountryModal
        currentDest={editedDestination}
        destinations={locationInfo}
        editMode={editMode}
        typeOfUser={typeOfUser}
        closeModal={closeModal}
        show={show}
        refreshDestinations={refreshUserData}
        locationsSelected={locations}
      />
    </Grid>
  );
};

export default Destinations;
