import React, { useState } from 'react';
import {
  Fab, Button, Grid, makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LanguagesAutoComplete from '../../../Form/LanguagesAutoComplete';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  '& > *': {
    zIndex: 100,
    position: 'relative',
  },

  submit: {
    margin: theme.spacing(3, 0, 3),
  },
}));

const AddJob = (props) => {
  const [open, setOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    languages: null,
  });
  const {
    updateLanguages,
    show,
    closeModal,
    userLanguages,
    mode,
    targetLanguage,
  } = props;

  const handleClose = () => {
    closeModal(false);
  };
  const errors = {};

  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.languages || verifyAll) {
      if (formData.languages === null) {
        errors.languages = 'Vous devez choisir au moins une langue!';
      }
    }

    return Object.keys(errors).length < 1;
  };

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      },
      validateForm(),
    );
  };
  const handleSubmit = () => {
    const newLanguages = [];
    formData.languages.forEach((lang) => {
      newLanguages.push({
        id: lang.id,
        name: lang.name
      });
    });
    const newUserLanguagesList = [
      ...userLanguages,
      ...newLanguages,
    ];

    let filteredLangs = newUserLanguagesList.filter((v, i, a) => a.findIndex((t) => (t.id === v.id)) === i);

    if (mode === 'edit') {
      // remove the owner from the array
      const newList = filteredLangs.filter((l) => l.id !== targetLanguage);
      filteredLangs = newList;
    }
    updateLanguages(filteredLangs);
    closeModal(true);
    setFormData({ languages: null });
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);
  return (
    <div>

      <Dialog
        className={classes.root}
        open={show ? open === false : open === true}
        onClose={handleClose}
        aria-labelledby="Langue"
        fullScreen={fullScreen}
        large="500px"
      >
        <Grid container spacing={2}>
          <DialogContent>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 0',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon/>
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle>Choisir une langue dans la liste</DialogTitle>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LanguagesAutoComplete
                  name="languages"
                  label="Choisir vos langues*"
                  multiple
                  onFormChange={onFormChange}
                  errors={errors}
                  formData={formData}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleSubmit}>Valider</Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AddJob;
