import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Hidden,
  ListItemAvatar,
  ListItemText,
  Fab,
  Link,
  Avatar,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import carouselImg1 from '../../images/carousel/metier-image-1.jpg';
import carouselImg2 from '../../images/carousel/metier-image-2.jpg';
import carouselImg3 from '../../images/carousel/metier-image-3.jpg';
import carouselImg4 from '../../images/carousel/metier-image-4.jpg';
import target from '../../images/icon/target.svg';
import handshake from '../../images/icon/handshake.png';
import strength from '../../images/icon/strength.png';
import algo from '../../images/icon/algo.svg';
import rocket from '../../images/icon/rocket.svg';
import vision from '../../images/icon/vision.png';
import teamImage1 from '../../images/moovejob-team-img-1.jpeg';
import teamImage2 from '../../images/moovejob-team-img-2.jpeg';
import iconMapCandidate from '../../images/icon-map-candidate.png';
import iconInterconnexionJob from '../../images/icon-interconnexion-job.png';
import iconEnterpriseCandidateMatch from '../../images/icon-enterprise-candidate-match.png';
import logoVDQ from '../../images/logo_vdq.png';
import logoDesJardins from '../../images/logo-desjardins.png';
import logoAirCanada from '../../images/logo_air_canada.png';
import logoMajorelle from '../../images/logo_majorelle_mobility.png';
import logoVilleShawinigan from '../../images/logo_ville_shawinigan.png';
import logoPatrimoineRH from '../../images/logo_patrimoine_rh.png';
import logoHarfangImmigration from '../../images/logo_harfang_immigration.png';
import referencementIllu from '../../images/referencement-illu.png';
import theme from '../theme';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from '../UI-Component/Footer';
import useSessionContext from '../sessions/useSessionContext';
import NewsCarousel from '../UI-Component/MainPageCarousel';
import CountUpAnimation from '../UI-Component/CountUpAnimation';
import { format } from 'date-fns';
import { fr } from "date-fns/locale";
import usa from '../../images/photos/usa_optimized.jpg';
import canada from '../../images/photos/canada_optimized.jpg';
import france from '../../images/photos/france_optimized.jpg';
import SignupMenu from '../UI-Component/SignupMenu';
import { Carousel } from 'react-responsive-carousel';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  main:{
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden'
    },
  },
  homeContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20vh',
    justifyContent: 'space-evenly',
    paddingBottom: '50px',
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      marginTop: '5vh',
    },
  },
  iconAction:{
    marginRight: 5,
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
  },
  linkDemo: {
    color: '#e5087e',
    textTransform: 'none',
    fontSize: '15px'
  },
  flexHeightDesktop: {
    height: 'auto',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  flexAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  bgHero: {
    position: 'relative',
    height: '90vh',
    '& > *': {
      position: 'absolute',
      zIndex: 1,
    },
    '& .carousel-root': {
      width: '100%',
      height: '100%',
      '& .slider-wrapper':{
        height: '100%',
      }
    },
    '& .carousel .slide': {
      height: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
      '& .carousel .slide': {
        height: '60vh',
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: '80vh',
      '& .carousel .slide': {
        height: '80vh',
      },
    },
  },
  carouselContainer: {
    backgroundRepeat: 'no-repeat',
    height: '90vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '82vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '91vh',
    },
  },
  videoContainer: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
    '&::after': {
      paddingTop: '56.25%',
      display: 'block',
      content: '""',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },

  bgGrey: {
    backgroundColor: '#f9f9f9!important'
  },
  media: {
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '20px'
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '375px',
    '& p': {
      textAlign: 'left',
      margin: '0 0 0 30%',
    },
  },
  logoStyle: {
    maxWidth: '300px',
    maxHeight: '200px',
    margin: '0 auto',
  },
  iconStyle:{
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    "& .MuiAvatar-img":{
      height: "unset"
    }
  },
  logoStyleSmall: {
    maxWidth: '30vh',
    margin: '0 auto',
  },
  slogan: {
    fontWeight: 'bold',
    margin: 0,
  },
  subTitle: {
    margin: '3vh 0 6vh',
    paddingTop: '5vh',
    textAlign: 'center',
    width: '100%',
  },
  statisticNumber: {
    fontSize: 65,
    textAlign: 'center',
    width: '100%',
    color: "#e5087e",
    fontWeight: 'bold',
  },
  statisticTitle: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
  },
  reverseButtonStyle: {
    fontSize: '1.3em',
    maxWidth: '30%',
    color: '#E5087E',
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  listMoove: {
    position: 'relative',
    margin: '0',
    '&::before': {
      content: '"."',
      position: 'absolute',
      top: '-18px',
      left: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '30px',
      width: '30px',
      height: '100%',
      fontSize: 45,
      color: theme.palette.secondary.main,
    },
    '& p': {
      margin: '0',
      marginLeft: '20px',
      padding: '0px',
    },
  },
  responsiveMargin: {
    margin: '4vh 0',
  },

  html: {
    height: '100%',
    margin: 0,
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
  scrollWithOffset: {
    position: 'relative',
    top: '-7vw',
    paddingTop: '7vw',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px!important',
      paddingRight: '10px!important'
    },
  },
  container: {
      backgroundColor: '#fff',
      border: '1px solid #fff',
      boxShadow: '0 20px 30px rgba(0, 0, 0, 0.04)',
      borderRadius: '1.6rem',
      padding: '1rem 1rem 1.6rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 1,
      transition: 'all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53)'
    },
  containerPhoto: {
    height: 'auto',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  destinationImageContainer: {
    width: '100%',
    height: '16.2rem',
    borderRadius: '1.2rem',
    filter: 'drop-shadow(0 20px 30px rgba(0, 0, 0, 0.04))',
    position: 'relative',
    zIndex: 1
  },
  destinationImage: {
    width: '100%',
    height: '100%',
    borderRadius: '1.2rem',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  subTitleDesc: {
    margin: '3vh 0 6vh',
    paddingTop: '5vh',
    textAlign: 'center',
    fontWeight: 700,
    width: '100%',
  },
  secondSubTitle:{
    textAlign: 'center',
    width: '100%',
    textAlign: 'left',
    margin: '0px'
  },
  reveContainer: {
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '20px 0px',
    },
  },
  listItemIcon: {
    '& .MuiListItemAvatar-root':{
      minWidth: '70px'
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      padding: '0px!important',
      '& .MuiListItemAvatar-root':{
        marginTop: '12px'
      }
    },
  },
  advantageContainer:{
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
    },
  },
  pink:{
    color: '#E4087E'
  },
  panelSlogan: {
    margin: "0px 20px"
  },
  panelAction: {
    margin: "0px 20px",
    marginBottom: "15px",
    width: '92%',
    [theme.breakpoints.down('md')]: {
      margin: "0px auto",
      marginBottom: "3vh",
      "& > ul":{
        display: "inline",
        "& > li":{
          margin: "10px 0px",
          "& > button":{
            width: "100%"
          }
        }
      }
    }
  },
  containerHomeText:{
    alignItems: 'flex-start!important',
    backgroundColor: '#ffffffde!important',
    borderRadius: '30px',
    maxWidth: '500px!important'
  },
  h1Slogan:{
    textAlign: "left",
    fontSize: "1.5em",
    marginBottom: "0px"
  },
  btnDisplayFlex: {
    display: `flex`,
    justifyContent: `center`,
    margin: '0px',
    height: '100%',
    '& li': {
      justifyContent: 'center',
      marginRight: '10px',
      padding: '0px',
      width: '100%!important',
      textAlign: 'center',
      
    },
  },
  iconAction:{
    marginRight: 5,
    width: '100%',
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
    '& a:hover': {
      textDecoration: 'none'
    },
  },
  bulletPoint: {
    fontSize: '1.1em',
    fontWeight: 'normal',
    '& span': {
      fontSize: '1em',
    },
  },
  referenceContainer:{
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 10
    },
    
  },
  referenceText:{
    fontWeight: 700,
    textAlign: 'center',
    margin: 5,
  },
  buttonStyle: {
    minWidth: 'unset',
    lineHeight: '18px',
    padding: '0px 12px',
    height: 44,
    margin: '7px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '15px'
    },
    '&:hover': {
      '& a': {
        color: '#E5087E',
        textTransform: 'none',
        textDecoration: 'none',
      }
    },
    '& a': {
      fontWeight: 600,
      color: '#fff',
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        color: '#E5087E',
      },
    },
  },
  referenceBtnLink: {
    color: '#e5087e',
    textTransform: 'none',
    fontSize: '16px',
    '&:hover': {
      color: '#E5087E',
    },
  },
  bold: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  listChip: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '15px',
    '& > *': {
      margin: theme.spacing(0.5),
      fontFamily: 'Dosis',
      paddingRight: '10px'
    },
    [theme.breakpoints.up('sm')]: {
      '& > :not(:last-child)':{
        borderRight: '1px solid grey',
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    
  },
}));

const Home = () => {
  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }

  const { user } = useSessionContext();

  const classes = useStyles(theme);

  const [statistics, setStatistics] = useState({
    number_of_candidates: 0,
    number_of_jobs: 0,
    number_of_matches: 0,
  });

  useEffect(() => {
    // Définir la fonction async pour récupérer les données
    const fetchData = async () => {
      try {
        const response = await fetch('/statistic/main');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStatistics(data.data); // Assurez-vous que la réponse du serveur correspond à cette structure
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    };

    fetchData();
  }, []);

  const today = format(new Date(), 'PPP', { locale: fr });
  return (
    <main className={classes.main}>
      {NewsCarousel()}
      <div className={classes.bgHero}>
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          interval={7000}
        >
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg1})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg2})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg3})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>
          <div 
            className={classes.carouselContainer}
            style={{
                backgroundImage: `url(${carouselImg4})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
          </div>

        </Carousel>
        <Container maxWidth={false} className={classes.homeContainer}>
          <Box  className={classes.containerHomeText}>
            <div className={classes.panelSlogan}>
              <Typography variant="h1" color='primary' className={classes.h1Slogan}>
                Talents et compétences <span className={classes.pink}>francophones</span>
                <br />
                Collaborer autrement : <span className={classes.pink}>recruter, prêter, investir</span>
              </Typography>
              <div className={classes.listChip}>
                <span>Recrutement international de francophones</span>
                <span>Immigrant investisseur</span>
                <span>Prêt de personnel entre entreprises</span>
                <span>Sous-Traitance internationale</span>
              </div>
              
            </div>
            <div className={classes.panelAction}>
              {!user && (
                <List className={classes.btnDisplayFlex}>
                    <ListItem>
                      <SignupMenu/>
                    </ListItem>
                    <ListItem
                    >
                       <Fab 
                        variant="extended" 
                        size="large"
                        title="demo"
                        className={classes.iconAction}
                        >
                        <Link className={classes.linkDemo} href={"https://calendly.com/moovejob/demo"} target="_blank">
                          Demander une démo
                        </Link>
                      </Fab>
                    </ListItem>
                </List>
              )}
            </div>
          </Box>
        </Container>
      </div>
      <div>
        <Container
          maxWidth={false}
          className={classes.scrollWithOffset}
          id="statistic"
        >
          <Box className={classes.flexHeightDesktop}>
            <Typography variant="h2" className={classes.subTitle}>
              Nos Chiffres le {today}
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={12} md={4}>
                <img src={iconMapCandidate} alt="logo" className={classes.logoStyle} />

                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_user_metier} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de Compétences métiers inscrites
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img src={iconInterconnexionJob} alt="logo" className={classes.logoStyle} />
                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_jobs} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de Métiers différents
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img src={iconEnterpriseCandidateMatch} alt="logo" className={classes.logoStyle} />
                <Typography variant="h2" className={classes.statisticNumber}>
                  <CountUpAnimation end={statistics.number_of_matches} duration={5} />
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  Nombres de matchs Entreprises-Candidats
                </Typography>
                <Typography variant="body2" className={classes.statisticTitle}>
                  dans les 30 derniers jours
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div>
        <Grid container>
          <Typography variant="h2" color="secondary" className={classes.subTitle}>
          Ouvrez-vous au monde!
          </Typography>
          <Grid item xs={12} md={6}>
            <div className={classes.videoContainer}>
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/RPqx5pTE38k"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container
              maxWidth={false}
              className={classes.scrollWithOffset}
              id="reves"
            >
              <Box className={`${classes.flexHeightDesktop} ${classes.bgGrey} ${classes.reveContainer}`}>
                <Typography variant="h2" className={classes.secondSubTitle}>
                  Nos 3 grands <span color='secondary'>rêves</span>
                </Typography>
                

                <List className={classes.listMooveContainer}>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Offrir la possibilité aux personnes ayant le rêve de
                      partir travailler et vivre à l’international de le
                      réaliser.
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Rendre accessible le recrutement international à toutes
                      les entreprises sans exception pour leur permettre de
                      continuer leur développement et leur croissance en créant
                      de la richesse.
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.listMoove}>
                    <Typography className={classes.bulletPoint}>
                      Contribuer significativement au rééquilibrage social
                      mondial par le reversement systématique d’un pourcentage
                      sur toutes les transactions au profit des organismes
                      mondiaux aidés.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: '15vh' }} className={classes.bgGrey}>
        <Container
          maxWidth={false}
          className={classes.scrollWithOffset}
          id="about"
        >
          <Box className={classes.flexHeightDesktop}>
            <Typography variant="h2" className={classes.subTitle}>
              À propos
            </Typography>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                        <Avatar alt="target" src={target} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Mission"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                              Faciliter la connexion des compétences francophones pour les candidats, les entreprises, les travailleurs autonomes ou les immigrants investisseurs, le tout sans intermédiaire! En associant les compétences des uns ou besoins et souhaits des autres pour leur permettre de créer ensemble de la valeur.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="rocket" src={rocket} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Type d’opportunités offertes"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              > La plateforme offre un accès simplifié à des talents internationaux pour les entreprises, et aux candidats, une porte vers des emplois, des contrats à l'étranger, directement et sans intermédiaires.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                      <Avatar alt="vision" src={vision} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Vision"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                              Devenir la référence mondiale en mobilité internationale des compétences en proposant un outil simple, intuitif et efficace pour connecter les humains entre eux au niveau professionnel.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="algo" src={algo} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Algorithme unique"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              >
                                Notre algorithme a été conçu à partir d'une matrice de plus de 1600 métiers et de près de 30 000 compétences professionnelles et techniques spécifiques à chaque métier.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item md={6}>
                <List>
                  <ListItem className={classes.listItemIcon}>
                      <ListItemAvatar>
                      <Avatar alt="strength" src={strength} className={classes.iconStyle}  />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Force"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.bulletPoint}
                              color="textPrimary"
                            >
                            Notre plateforme offre aussi un accompagnement à la carte pour l'entreprise et pour le futur collaborateur de celle-ci selon leurs attentes dans l’objectif de simplifier les procédures, l’intégration, et ce pendant tout le processus de recrutement et d'immigration.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List>
                    <ListItem className={classes.listItemIcon}>
                        <ListItemAvatar>
                          <Avatar alt="handshake" src={handshake} className={classes.iconStyle}  />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Réseau de partenaires"
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.bulletPoint}
                                color="textPrimary"
                              >
                                Notre réseau de partenaires propose des services exclusifs pour vous accompagner à chaque étape du processus de recrutement. Bénéficiez d'un suivi sur mesure par des experts qualifiés et obtenez des conseils spécifiquement adaptés à votre secteur d'activité.
                              </Typography>
                            </React.Fragment>
                          }
                        />
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
            
          </Box>
        </Container>
      </div>
    

      <Grid container  style={{ marginTop: '30px', marginBottom: '5vh', backgroundColor: 'white' }}  spacing={2}>
        <Grid item xs={12} md={12} >
            <div className={classes.referenceContainer}>
              <Typography variant="h2" color="secondary" className={classes.subTitle}>
                Parrainez et soyez récompensé!
              </Typography>
              
            </div>
            <div className={classes.referenceContainer}>
              <img src={referencementIllu} alt="moovejob programme parrainage image" />
            </div>
            <div className={classes.referenceContainer}>
              <Typography variant="body2" className={classes.referenceText}>
                Gagnez 200 $ pour chaque candidat parrainé et embauché.
              </Typography>
            </div>
            <div className={classes.referenceContainer}>
              <Typography variant="body2" className={classes.referenceText}>
                Recommandez une entreprise et gagnez 10% de commission sur son abonnement annuel.
              </Typography>
            </div>
            <div className={classes.referenceContainer}>
              <Button
              size='sm'
                type="submit"
                className={classes.buttonStyle}
                onClick={()=>navigate('/parrainage-candidat')}
              >
                <Link className={classes.referenceBtnLink} >
                Parrainer un Candidat
                </Link>
              </Button>
              <Button
              size='sm'
                type="submit"
                className={classes.buttonStyle}
                onClick={()=>navigate('/parrainage-entreprise')}
              >
                <Link className={classes.referenceBtnLink} >
                Parrainer une Entreprise
                </Link>
              </Button>
            </div>
        </Grid>
        
      </Grid>

      <Grid container  style={{ marginTop: '15vh', backgroundColor: 'white' }} spacing={2}>
        <Grid item xs={12} md={6} className={classes.media}>
          <Hidden smDown>
            <img src={teamImage2} alt="moovejob team image" />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container
            maxWidth={false}
            className={`${classes.advantageContainer} ${classes.bgGrey}`}
            id="avantages"
          >
            <Box className={classes.flexHeightDesktop}>
              <Typography variant="h2" className={classes.secondSubTitle}>
                Les avantages moovejob pour les entreprises
              </Typography>
              <List>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Accès immédiat aux candidats et aux compétences recherchées.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Aucun intermédiaire.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réduction de vos frais de recrutement jusqu'à 80%.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réception instantanée d’une alerte dès qu'un candidat
                    répondant à vos critères de recherche est en ligne.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Affichage de vos postes et opportunités d’affaires à la communauté francophone mondiale, soit plus de 300 millions de personnes.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Outil unique, solutions multiples pour faire face à la pénurie de main-d’oeuvre.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Accompagnement professionnel si vous le souhaitez, aux étapes de votre choix (sélection, validation, immigration, accompagnement administratif, relocalisation, etc.).
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                  Notre plateforme se distingue par son exclusivité à la communauté francophone, à la mobilité internationale et par son algorithme de présélection, basé sur les compétences et les aptitudes personnelles et non plus uniquement sur les métiers.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Container>
        </Grid>
        
      </Grid>
      <Grid container  style={{ marginTop: '30px',marginBottom: '15vh', backgroundColor: 'white' }}  spacing={2}>
        <Grid item xs={12} md={6}>
          <Container maxWidth={false} className={`${classes.advantageContainer} ${classes.bgGrey}`}>
            <Box
              className={classes.flexHeightDesktop}
            >
              <Typography variant="h2" className={classes.secondSubTitle}>
                Les avantages moovejob pour les candidats
              </Typography>
              <List>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Accès immédiat aux emplois et aux stages disponibles dans
                    les pays francophones, selon votre sélection.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Totalement gratuit, même pour les travailleurs indépendants.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">Aucun intermédiaire.</Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Aucun document à télécharger à l’exception de votre CV.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Réception instantanée d’une alerte dès qu'une opportunité
                    d'emploi ou de stage vous correspondant est en ligne.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Accompagnement professionnel si vous le souhaitez, aux
                    étapes de votre choix (immigration, accompagnement
                    administratif, relocalisation, logement, finance, etc.).
                  </Typography>
                </ListItem>
                <ListItem className={classes.listMoove}>
                  <Typography variant="body2">
                    Notre plateforme se distingue par son exclusivité à la
                    mobilité internationale et par son algorithme de
                    présélection, basé sur les compétences et aptitudes
                    personnelles et non plus uniquement sur les métiers.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} className={classes.media}>
          <Hidden smDown>
            <img src={teamImage1} alt="moovejob team image" />
          </Hidden>
        </Grid>
        
      </Grid>
      <div className={classes.bgGrey}>
        <Container
          maxWidth="md"
          className={classes.scrollWithOffset}
          id="topDestination"
        >
          <Box className={classes.containerPhoto}>
            <Typography variant="h2" className={classes.subTitle}>
              Top 3 des destinations des candidats
            </Typography>
            <Grid container spacing={2} >
              <Grid item lg={4} xs={12} md={4} className={classes.topDestination} >
                <Grid className={classes.container}>
                  <div className={classes.destinationImageContainer}>
                    <img src={canada} alt="destination-canada" className={classes.destinationImage} />
                  </div>

                  <Typography variant="h3" className={classes.subTitleDest}>
                    Canada
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12} md={4} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={france} alt="destination-france" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    France
                  </Typography>
                </Grid>

              </Grid>
              <Grid item lg={4} xs={12} md={4} className={classes.topDestination} >
                <Grid className={classes.container}>

                  <div className={classes.destinationImageContainer}>
                    <img src={usa} alt="destination-usa" className={classes.destinationImage} />
                  </div>
                  <Typography variant="h3" className={classes.subTitleDest}>
                    États-Unis
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Container maxWidth={false}>
            <Box
              className={classes.flexHeightDesktop}
              id="partener"
              style={{ paddingBottom: '5vh' }}
            >
              <Typography variant="h2" className={classes.subTitle}>
                Nos Partenaires
              </Typography>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <a href="https://www.ville.quebec.qc.ca/" target='_blank'><img src={logoVDQ} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <a href="https://www.patrimoine-rh.com/" target='_blank'><img src={logoPatrimoineRH} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <a href="https://www.shawinigan.ca/" target='_blank'><img src={logoVilleShawinigan} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <a href="https://www.desjardins.com/" target='_blank'><img src={logoDesJardins} alt="logo partenaire desjardins" className={classes.logoStyle} /></a>
                </Grid>

              </Grid>
              <Grid container style={{justifyContent:'center'}}>
                <Grid item xs={12} md={3}>
                  <a href="https://majorelle-mobility.com/" target='_blank'><img src={logoMajorelle} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <a href="https://www.aircanada.com/ca/fr/aco/home.html" target='_blank'><img src={logoAirCanada} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <a href="https://www.harfangimmigration.com/" target='_blank'><img src={logoHarfangImmigration} alt="logo" className={classes.logoStyle} /></a>
                </Grid>
              </Grid>
              <div style={{height:'0',overflow:'hidden'}}>
                <a href='https://moovejob.com/article/immigration-du-bresil-joinville-au-quebec-la-tuque-immigration-dun-soudeur-et-de-sa-famille'>Du Brésil (Joinville) au Québec (La tuque), immigration d'un soudeur et de sa famille</a>
                <a href='https://moovejob.com/article/les-etapes-essentielles-un-projet-immigration-reussi'>​Les étapes essentielles d’un projet d’immigration réussi!</a>
                <a href='https://moovejob.com/article/moovejob-un-reve-une-histoire-une-vision-qui-vous-ouvrent-la-porte-vers-linternational'>moovejob, un rêve, une histoire, une vision.. qui vous ouvrent la porte vers l’international!</a>
                <a href='https://moovejob.com/article/penurie-main-oeuvre-phenomene-mondial'>La pénurie de main-d'œuvre est devenue un phénomène mondial, selon la dernière étude réalisée par l'institut économique Rexecode.</a>
                <a href='https://moovejob.com/article/offre-de-stage-en-ressources-humaines-et-communication'>Offre de stage en Ressources Humaines et communication</a>
                <a href='https://moovejob.com/article/mon-echange-universitaire-lexperience-qui-ma-donne-envie-daller-travailler-au-quebec'>Mon échange universitaire : l’expérience qui m’a donné envie d’aller travailler au Québec</a>
                <a href='https://moovejob.com/article/natech-training-nouveau-partenaire-de-moovejob-pour-la-formation-et-devaluation-des-candidats-en-tunisie'>Natech Training nouveau partenaire de moovejob pour la formation et les évaluations des candidats en Tunisie</a>
                <a href='https://moovejob.com/article/mythes-realites-sur-le-recrutement-international-sans-tabou'>​Mythes & réalités sur le recrutement international, sans tabou!</a>
                <a href='https://moovejob.com/article/liste-des-metiers-les-plus-recherches-en-2021-et-a-quoi-peut-on-sattendre-en-2025'>​LISTE DES MÉTIERS LES PLUS RECHERCHÉS EN 2021 ET À QUOI PEUT-ON S’ATTENDRE EN 2025!</a>
                <a href='https://moovejob.com/article/partenaire-devaluation-de-professionnels-au-cameroun'>Partenaire d'évaluation de professionnels au Cameroun</a>
                <a href='https://moovejob.com/article/les-trois-nouvelles-mesures-a-retenir-en-faveur-des-travailleurs-etrangers-temporaires-au-quebec'>Les trois nouvelles mesures à retenir en faveur des travailleurs étrangers temporaires au Québec!</a>
                <a href='https://moovejob.com/article/moovement-infolettre-septembre-2021'>moovement, infolettre Septembre 2021</a>
                <a href='https://moovejob.com/article/recruter-facilement-pour-votre-entreprise-a-linternational'>Trouver facilement le bon candidat pour votre entreprise</a>
                <a href='https://moovejob.com/article/4000-postes-dinfirmieres-ouverts-au-quebec-inedit'>4000 postes d'infirmières ouverts au Québec ! Inédit</a>
                <a href='https://moovejob.com/article/citations-inspirantes-pour-booster-sa-carriere'>Citations inspirantes pour booster sa carrière</a>
                <a href='https://moovejob.com/article/quels-permis-de-travail-ou-visa-pour-le-quebec-recrutement-international'>Quels permis de travail ou visa pour le Québec, recrutement international ?</a>
                <a href='https://moovejob.com/article/le-francais-langue-mondiale-'>Le français, langue mondiale ?</a>
                <a href='https://moovejob.com/article/quels-secteurs-recruteront-le-plus-2022'>Quels secteurs recruteront le plus d'ici 2022 ?</a>
                <a href='https://moovejob.com/article/travailler-au-canada-les-permis-de-travail'>​Travailler au Canada : les permis de travail</a>
                <a href='https://moovejob.com/article/recrutement-international-lintegration-des-salaries-etrangers'>Recrutement international : l'intégration des salariés étrangers</a>
                <a href='https://moovejob.com/article/les-infirmieres-et-le-reve-de-travailler-au-quebec'>Les infirmières et le rêve de travailler au Québec</a>
                <a href='https://moovejob.com/article/hotellerie-restauration-les-patrons-peinent-a-recruter'>Hôtellerie-restauration : les patrons peinent à recruter</a>
                <a href='https://moovejob.com/article/emploi-la-grande-demission-sur-fond-de-penurie'>Emploi : la grande démission sur fond de pénurie mondiale</a>
                <a href='https://moovejob.com/article/penurie-mondiale-meme-les-peres-noel-manquent-a-lappel'>​Pénurie mondiale : même les pères noël manquent à l’appel</a>
                <a href='https://moovejob.com/article/emploi-boostez-votre-visibilite-aupres-des-recruteurs-a-linternational'>Emploi : Boostez votre visibilité auprès des recruteurs à l’international</a>
                <a href='https://moovejob.com/article/recherche-demploi-5-conseils-pour-se-demarquer-des-autres-candidats_international'>Recherche d'emploi : 5 conseils pour se démarquer des autres candidats à l'international</a>
                <a href='https://moovejob.com/article/entretien-dembauche-virtuel-mettez-toutes-les-chances-de-votre-cote'>​Entretien d'embauche virtuel : mettez toutes les chances de votre côté!​</a>
                <a href='https://moovejob.com/article/reussir-un-entretien-dembauche-a-distance-pour-un-poste-a-letranger'>Réussir un entretien d’embauche à distance pour un poste à l’étranger</a>
                <a href='https://moovejob.com/article/vous-recrutez-a-linternational-ouverture-de-la-saison-eic-jeune-professionnels-2022'>Vous recrutez à l’international ? Ouverture de la saison EIC Jeune professionnels 2022</a>
                <a href='https://moovejob.com/article/y-a-t-il-vraiment-une-penurie-de-personnel-en-sante-'>Y a-t-il vraiment une pénurie de Personnel en santé ?</a>
                <a href='https://moovejob.com/article/recruter-des-travailleurs-qualifies-en-tunisie-soudure-plasturgie-fonderie'>Recruter des travailleurs qualifiés en Tunisie (soudure, plasturgie, fonderie)</a>
                <a href='https://moovejob.com/article/differences-interculturelles-entre-la-France-et-le-Quebec'>27 différences "interculturelles" entre la France et le Québec</a>
                <a href='https://moovejob.com/article/temoignage-dexpatriee-extrait-quebec'>​Témoignage d'expatriée (extrait) - Québec</a>
                <a href='https://moovejob.com/article/recrutement-international-procedures-et-visas-dimmigration-au-quebec'>Recrutement international, procédures et visas d'immigration au Québec</a>
                <a href='https://moovejob.com/article/offre-de-stage-commercial-developpement-de-marche-pays-francophones'>Offre de stage commercial – Développement de marché pays francophones</a>
                <a href='https://moovejob.com/article/talents-de-femmes-temoignages-de-nos-candidates-qui-souhaitent-immigrer-au-quebec'>Talents de femmes - Témoignages de nos candidates qui souhaitent immigrer au Québec</a>
                <a href='https://moovejob.com/article/comment-contribuer-a-un-monde-meilleur-a-notre-niveau'>Comment contribuer à un monde meilleur, à notre niveau.</a>
                <a href='https://moovejob.com/article/comment-obtenir-un-permis-et-exercer-au-quebec-en-tant-quingenieurs-formes-a-letranger'>Comment obtenir un permis et exercer au Québec en tant qu'ingénieurs formés à l'étranger</a>
                <a href='https://moovejob.com/article/comment-peaufiner-son-projet-dimmigration-au-quebec-pour-faire-mouche-aupres-des-employeurs'>Comment peaufiner son projet d’immigration au Québec pour faire « mouche »  auprès des employeurs</a>
                <a href='https://moovejob.com/article/comment-optimiser-arrivee-de-nouveaux-collaborateurs-venant-de-international'>Comment optimiser l’arrivée de nouveaux collaborateurs venant de l’international ?</a>
                <a href='https://moovejob.com/article/le-recrutement-via-les-plateformes-de-recrutement-specialisees-en-attraction-de-talents-au-niveau-international'>Le recrutement via les plateformes de recrutement spécialisées en attraction de talents au niveau international</a>
                <a href='https://moovejob.com/article/comment-se-preparer-a-la-diversite-culturelle-en-entreprise'>Comment se préparer à la diversité culturelle en entreprise?</a>
                <a href='https://moovejob.com/article/cv-canadien-suivez-le-guide'>CV canadien: suivez le guide!</a>
                <a href='https://moovejob.com/article/vous-navez-pas-de-connexion-avec-des-employeurs'>Vous n’avez pas de connexion avec des employeurs?</a>
                <a href='https://moovejob.com/article/recrutement-international-et-si-vos-futurs-collaborateurs-venaient-du-mali'>Recrutement international : Et si vos futurs collaborateurs venaient du Mali?</a>
                <a href='https://moovejob.com/article/les-25-erreurs-a-eviter-qui-agacent-les-recruteurs-et-les-employeurs-au-quebec-et-ailleurs'>Les 25 erreurs à éviter qui agacent les recruteurs et les employeurs (au Québec et ailleurs)</a>
                <a href='https://moovejob.com/article/retroplanning-reussissez-votre-immigration-au-quebec'>Checklist: réussissez votre immigration à l'étranger!</a>
                <a href='https://moovejob.com/article/10209-competences-metiers-disponibles-au-10-01-2023'>10 209 compétences métiers disponibles au 10/01/2023</a>
                <a href='https://moovejob.com/article/moovejobcom-arrive-officiellement-en-france-pour-soutenir-les-entreprises'>moovejob.com arrive officiellement en France pour soutenir les entreprises!</a>
                <a href='https://moovejob.com/article/canada-2041-une-population-plus-diverse-et-cosmopolite'>Canada 2041 : une population plus diverse et cosmopolite</a>
                <a href='https://moovejob.com/article/recruter-a-international-procedures-immigration-pour-la-france'>Recruter à l’international : Procédures d’immigration pour la France</a>
                <a href='https://moovejob.com/article/10-raisons-de-venir-travailler-au-quebec-opportunites-internationale-pour-les-francophones-sur-moovejob'>10 raisons de venir travailler au Québec : opportunités internationale pour les francophones sur moovejob</a>
                <a href='https://moovejob.com/article/la-ville-de-quebec-ainsi-que-certaines-regions-du-quebec-vont-frapper-un-mur-dans-les-prochains-mois'>La ville de Québec ainsi que certaines régions du Québec vont frapper un mur dans les prochains mois!</a>
                <a href='https://moovejob.com/article/moovejob-vous-invite-a-decouvrir-shawinigan-ville-du-quebec'>moovejob vous invite à "Découvrir Shawinigan", ville du Québec</a>
                <a href='https://moovejob.com/article/moovejob-vous-invite-a-immigrer-a-shawinigan-au-quebec'>moovejob vous invite à immigrer à Shawinigan au Québec</a>
                <a href='https://moovejob.com/article/nouveau-metier-educateur-petite-enfance'>Nouveau métier - Educateur petite enfance</a>
                <a href='https://moovejob.com/article/moovejob-vous-invite-economie-shawinigan'>moovejob vous invite à découvrir l'économie à Shawinigan</a>
                <a href='https://moovejob.com/article/moovejob-la-plateforme-quebecoise-qui-comble-la-penurie-educateurs-petite-enfance-et-facilite-le-recrutement-international'>​moovejob : La plateforme québécoise qui comble la pénurie d'éducateurs petite enfance et facilite le recrutement à l'international</a>
                <a href='https://moovejob.com/article/decouvrez-la-fascinante-region-de-la-capitale-nationale-au-quebec'>Découvrez la fascinante région de la Capitale-Nationale au Québec !</a>
                <a href='https://moovejob.com/article/partenariat-entre-moovejob-et-la-ville-de-shawinigan-pour-le-recrutement-international-de-francophones'>Partenariat entre moovejob et la Ville de Shawinigan pour le recrutement international de francophones</a>
                <a href='https://moovejob.com/article/de-quoi-ai-je-besoin-visiter-le-canada-autorisation-de-voyage-electronique-ave-ou-visa'>De quoi ai-je besoin si je souhaite visiter le Canada ? Une autorisation de voyage électronique (AVE) ou un visa ?</a>
                <a href='https://moovejob.com/article/le-programme-experience-internationale-canada-eic-de-quel-permis-ai-je-besoin-vacances-travail-jeune-pro-ou-stage-'>Le Programme Expérience internationale Canada (EIC) : De quel permis ai-je besoin ? Vacances-travail, Jeune-Pro ou Stage ?</a>
                <a href='https://moovejob.com/article/stage-commercial-developpement-de-marche-france-suisse-belgique'>​Stage commercial – Développement de marché France – Suisse - Belgique</a>
                <a href='https://moovejob.com/article/stage-commercial-developpement-de-marche-afrique-pays-francophone'>​Stage commercial – Développement de marché Afrique (pays francophone)</a>
                <a href='https://moovejob.com/article/la-penurie-de-main-duvre-en-france-et-dans-le-monde-frappe-fort'>La pénurie de main-d’œuvre en France et dans le monde, frappe fort!</a>
                <a href='https://moovejob.com/article/programmeur-bienvenue-chez-moovejobcom-osez-lexcellence-'>Programmeur(euse) RUBY, bienvenue chez moovejob.com – Osons l'Excellence ensemble!</a>
                <a href='https://moovejob.com/article/15-569-competences-et-527-metiers-disponibles-au-15-01-2024'>15 569 compétences et 527 métiers disponibles au 15/01/2024</a>
                <a href='https://moovejob.com/article/salon-les-emplois-en-seine-le-14-et-15-mars-2024-a-rouen'>Salon "Les emplois en Seine" le 14 et 15 mars 2024 à Rouen</a>
                <a href='https://moovejob.com/article/participez-a-3-salons-pour-emploi-en-france-mars-2024'>Participez à 3 salons pour l'emploi en France - Mars 2024</a>
                <a href='https://moovejob.com/article/moovejob-laureat-du-defi-quebec-automne-2023'>moovejob.com, lauréat du défi Québec automne 2023</a>
                <a href='https://moovejob.com/article/offre-exclusive-air-canada-pour-les-candidats-moovejob'>Offre exclusive Air Canada pour les candidats moovejob !</a>
                <a href='https://moovejob.com/article/liste-des-professions-eligibles-au-traitement-simplifie-en-2024-au-quebec'>Liste des professions éligibles au traitement simplifié en 2024 au Québec</a>
                <a href='https://moovejob.com/article/demarches-dobtention-dun-permis-de-conduire-canadien-classe-1'>Démarches d’obtention d’un permis de conduire canadien Classe 1</a>
                <a href='https://moovejob.com/article/recrutement-international-avenir-du-et-au-quebec-des-entreprises-proactives'>​​Recrutement international: L'avenir dû et au Québec des entreprises proactives!</a>
                <a href='https://moovejob.com/article/rencontre-avec-domoina-la-specialiste-du-recrutement-qui-connecte-madagascar-et-le-quebec-sur-moovejobcom'>Rencontre avec Domoina, la spécialiste du recrutement qui connecte Madagascar et le Québec sur moovejob.com</a>
                <a href='https://moovejob.com/article/guide-des-equivalences-de-diplomes-entre-la-france-et-le-quebec-votre-passeport-pour-une-nouvelle-aventure-avec-moovejobcom'>Guide des équivalences de diplômes entre la France et le Québec : Votre passeport pour une nouvelle aventure avec moovejob.com</a>
                <a href='https://moovejob.com/article/partir-travailler-ailleurs-et-au-quebec-en-particulier-guide-pour-les-expatries'>Partir travailler ailleurs et au Québec en particulier : Guide pour les expatriés</a>
                <a href='https://moovejob.com/article/francophonie-et-mobilite-les-secrets-pour-reussir-en-tant-que-travailleur-francophone'>Francophonie et mobilité : Les secrets pour réussir en tant que travailleur francophone</a>
                <a href='https://moovejob.com/article/le-pret-de-main-oeuvre-en-france-un-modele-pour-le-quebec'>Le prêt de main-d’œuvre en France : Un modèle pour le Québec?</a>
                <a href='https://moovejob.com/article/vers-un-nouveau-modele-emploi-la-mobilite-des-competences-entre-entreprises'>Vers un nouveau modèle d'emploi : la mobilité des compétences entre entreprises!</a>
                <a href='https://moovejob.com/article/biometrie-le-passage-pour-les-candidats-prets-a-travailler-et-vivre-a-letranger'>Biométrie : Le Passage pour les candidats prêts à travailler et vivre à l'étranger</a>
                <a href='https://moovejob.com/article/17590-competences-sur-moovejob-au-22-mai-2024-votre-ressource-pour-le-recrutement'>17590 compétences sur moovejob au 22 Mai 2024 : votre ressource pour le recrutement</a>
                <a href='https://moovejob.com/article/employeur-quoi-faire-lors-de-embauche-un-etudiant-etranger'>Employeur : Quoi faire lors de l'embauche d'un étudiant étranger ?</a>
                <a href='https://moovejob.com/article/comment-le-pret-de-personnel-entre-entreprises-peut-renforcer-le-marche-du-travail-au-quebec'>Comment le Prêt de Personnel entre entreprises peut renforcer le marché du travail au Québec</a>
              </div>

            </Box>
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
};

export default Home;
