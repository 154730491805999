import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardHeader, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import theme from '../../../theme';
import LangueModal from '../FormModal/langueModal';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
}));

const Langues = (props) => {
  const { languages } = props;
  const [userLanguages, setUserLanguages] = useState({
    languages: [...languages],
  });
  const { refreshUserData, updateUser } = useSessionContext();

  React.useEffect(() => {
    setUserLanguages({ languages: [...userLanguages.languages] });
  }, [languages]);

  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  const classes = useStyles(theme);

  const [targetLanguage, setTargetLanguage] = useState(0);
  const [modalMode, setModalMode] = useState('create');

  const updateLanguages = (lastLangs) => {
    const raw = {
      user: {
        languages: lastLangs,
      },
    };

    updateUser(raw).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.log('could not update the users languages');
        }
      }
      if (response.status === 200) {
        setUserLanguages({
          languages: lastLangs,
        });
        refreshUserData();
      }
    });
  };

  const CarteLangues = (LangArr) => {
    const carteArr = [];
    LangArr.forEach((lang, i) => {
      carteArr.push(
        <>
          <Card
            key={lang.name}
            className={classes.rootCard}
            square
            elevation={1}
            key={i}
          >
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <CardHeader title={lang.name} />
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Fab
                  id={`deleteLanguage-${lang.id}`}
                  aria-label="Delete"
                  onClick={() => {
                    const filtertedLangs = userLanguages.languages.filter(
                      (l) => l.id !== lang.id,
                    );
                    updateLanguages(filtertedLangs);
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </Grid>
            </Grid>
          </Card>
        </>,
      );
    });
    return carteArr;
  };
  return (
    <Grid container>
      <LangueModal
        targetLanguage={targetLanguage}
        updateLanguages={updateLanguages}
        mode={modalMode}
        userLanguages={userLanguages.languages}
        closeModal={closeModal}
        show={show}
      />
      <Card className={classes.rootCard} square elevation={0}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <CardHeader title="Langues" className={classes.profileTabTitle} />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 0',
            }}
            justify="center"
          >
            <Fab
              id="addLanguage"
              color="secondary"
              aria-label="add"
              onClick={() => {
                refreshUserData();
                setModalMode('add');
                openModal();
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Card>
      {/* debut de la loop langues */}
      {CarteLangues(userLanguages.languages)}
    </Grid>
  );
};

export default Langues;
