import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import theme from '../../theme';
import FormConfirmAction from "../ConfirmationBox"
import useSessionContext from "../../sessions/useSessionContext";

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
    textAlign: 'left',
  },
  profileTabTitle: {

    '& span': {
      fontWeight: 600,
       fontSize: '1.928rem',
        textAlign: 'center',
    },
  },
}));


const Settings = () => {
  const classes = useStyles(theme);
  const { user } = useSessionContext();

  const [userSettings, setUserSettings] = useState({
      typeOfUser: '',
      isSubscribed: false,
      subscribedTo: null
  });

  useEffect(()=>{
    setUserSettings({
      typeOfUser: user.type_of_user,
      isSubscribed: user.is_subscribed,
      subscribedTo: user.subscribed_to
    })
  },[])

  return (
    <div>
      <Grid container>
        <Card square className={classes.rootCard} elevation={0}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <CardHeader
                title="Paramètres du compte"
                className={classes.profileTabTitle}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
              }}
            ></Grid>
          </Grid>
        </Card>
        <Card className={classes.rootCard} square elevation={1}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
              <FormConfirmAction userSettings={userSettings}>
                </FormConfirmAction>
               </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default Settings;
