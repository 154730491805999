import React, { useEffect } from 'react';
import { Button, Typography, Grid, makeStyles } from '@material-ui/core';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  reverseButtonStyleProfile: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },

  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  html: {
    height: '100%',
    margin: 0,
  },
  root: {
    display: 'block',
    margin: '2rem 0',
    width: '100%',
    '& span': {
      fontSize: '1.5rem',
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  rootUploadProfile: {
    margin: 0,
    width: '100%',
    '& div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& span': {
      fontSize: '1.5rem',
      fontWeight: ' 500',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    display: 'none',
  },
}));

const UploadCV = ({ userType, onFormChange, formData, errors }) => {
  const classes = useStyles();
  const { user, SnackBar } = useSessionContext();
  const { informError } = SnackBar;

  const fileUpload = ({ target }) => {
    if (target.files.length > 0) {
      const { type } = target.files[0];
      if (type != 'application/pdf') {
        informError('Veuillez utiliser un document de type PDF');
      } else {
        onFormChange({ target: { name: 'linkedCv', value: target.files[0] } });
      }
    }
  };

  if (!user) {
    return (
      <div className={classes.root}>
        <Grid item xs={12}>
          {userType === 'Candidate' && !user && (
            <Typography variant="h2" className={classes.smallTitle}>
              Déposez votre CV pour accélerer votre processus d'embauche.
            </Typography>
          )}
          {userType === 'Candidate' && !user && (
            <input
              accept=".pdf"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={fileUpload}
            />
          )}
          <label htmlFor="contained-button-file">
            {userType === 'Candidate' && !user ? (
              <Button
                component="span"
                className={classes.reverseButtonStyle}
                style={{
                  width: '100%',
                  fontSize: '1.5rem !important',
                  textTransform: 'none',
                  fontFamily: 'Dosis,"Helvetica Neue",Arial,sans-serif',
                  fontWeight: '500 !important',
                  textAlign: 'center',
                }}
              >
                Téléversez votre CV en format PDF
              </Button>
            ) : (
              <Button
                component="span"
                className={classes.reverseButtonStyleProfile}
                style={{
                  width: '100%',
                  fontSize: '1.5rem !important',
                  textTransform: 'none',
                  fontFamily: 'Dosis,"Helvetica Neue",Arial,sans-serif',
                  fontWeight: '500 !important',
                  textAlign: 'center',
                }}
              >
                Téléversez votre CV en format PDF
              </Button>
            )}
          </label>
          {userType === 'Candidate' && !user && formData.linkedCv && (
            <>
              <Typography variant="h2" className={classes.smallTitle}>
                Nom de fichier: {formData.linkedCv.name}
              </Typography>
            </>
          )}
          {errors.linkedCv && (
            <p className={`${classes.error} ${'error'}`}>{errors.linkedCv}</p>
          )}
        </Grid>
      </div>
    );
  }
  return (
    <div className={classes.rootUploadProfile}>
      <Grid item xs={12}>
        {userType === 'Candidate' && (
          <input
            accept=".pdf"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={fileUpload}
          />
        )}
        <label htmlFor="contained-button-file">
          {userType === 'Candidate' && !user ? (
            <Button
              component="span"
              className={classes.reverseButtonStyle}
              style={{
                width: '100%',
                fontSize: '1.5rem !important',
                textTransform: 'none',
                fontFamily: 'Dosis,"Helvetica Neue",Arial,sans-serif',
                fontWeight: '500 !important',
                textAlign: 'center',
              }}
            >
              Téléversez votre CV en format PDF
            </Button>
          ) : (
            <Button
              component="span"
              className={classes.reverseButtonStyleProfile}
              style={{
                width: '100%',
                fontSize: '1.5rem !important',
                textTransform: 'none',
                fontFamily: 'Dosis,"Helvetica Neue",Arial,sans-serif',
                fontWeight: '500 !important',
                textAlign: 'center',
              }}
            >
              1. Ajouter ou mettre à jour votre CV
            </Button>
          )}
        </label>
      </Grid>
    </div>
  );
};

export default UploadCV;
