import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getWorkTypeSearch } from '../Functions/Get-query/getWorkTypeSearch';

const useStyles = makeStyles(() => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const parseFromElasticSearchResults = (results) => {
  const parsedServicesArray = [];
  results.forEach((res) => {
    parsedServicesArray.push({
      id: res._source.id,
      name: res._source.name,
    });
  });
  return parsedServicesArray;
};

const WorkTypeAutoComplete = ({
  name,
  onFormChange,
  formData,
  errors,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setWorkTypeList([]);
    }
  }, [isOpen]);

  const onTextChanged = (event) => {
    const query = event.target.value;
    if (query === null) {
      setWorkTypeList([]);
      return;
    }

    setLoading(true);
    getWorkTypeSearch(query).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.total > 0) {
          const newList = parseFromElasticSearchResults(response.results);
          setWorkTypeList(newList);
        }
        setLoading(false);
      } else {
        setWorkTypeList([]);
        setLoading(true);
        console.error("Une erreur est survenue" + response)
      }

    });
  };

  const handleWorkTypeSelection = (event, data) => {
    if (data === null) {
      return onFormChange({
        target: {
          name: 'workType',
          value: null,
        },
      });
    }
    
    onFormChange({
      target: {
        name: 'workType',
        value: {
          id: data.id,
          name: data.name,
        },
      },
    });
  };
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        value={formData.workType}
        noOptionsText="En chargement..."
        onOpen={onTextChanged}
        onClose={() => setWorkTypeList([])}
        name="work_type"
        id="work_type"
        loading={isLoading}
        options={workTypeList}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        key={(option) => option.id}
        disabled={disabled}
        onChange={handleWorkTypeSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onTextChanged}
            onBlur={onTextChanged}
            variant="outlined"
            error={!!errors.work_type}
            label="Compétence (en majuscules sans accents)"
            placeholder="Compétence"
          />
        )}
      />
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

export default WorkTypeAutoComplete;
