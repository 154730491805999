import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Chip
} from '@material-ui/core';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import FormMessageSender from './MessagePopUp';
import { apiUrl } from '../../Functions/apiUrl';
import { errorHandling } from '../../Functions/errorHandling';
import DisplayBannerButton from '../DisplayBannerButton';
import JobModal from './FormModal/JobModal';
import ViewCv from '../ViewCurriculumModal';
import LabelIcon from '@material-ui/icons/Label';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
    textAlign: 'left',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
    },
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  listItem: {
    borderBottom: '1px solid #ebebeb',
    paddingBottom: '3vh',
    display: 'block',
  },
  listText: {
    paddingRight: '40px',
  },
  listTextTime: {
    paddingRight: '40px',
    width: '100%',
  },
  viewedItem: {
    opacity: '0.3',
  },

  reverseButtonStyle: {
    color: '#E5087E',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  listItemSecondary: {
    display: 'flex',
    flexDirection: 'row',
    top: '25px',
    columnGap: '10px',
  },
  chipLabel:{
    fontWeight:700
  },
  chipLabelService:{
    marginTop: '10px',
    fontWeight:700,
    color: "#1575ca",
    border: "1px solid #1575ca"
  }
}));

const Notifications = () => {
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);
  const [metierData, setMetierData] = useState({});
  const [cvData, setCvData] = useState({});
  const [showViewMetierModal, setShowViewMetierModal] = useState(false);
  const [showViewCvModal, setShowViewCvModal] = useState(false);
  const closeViewEditModal = () => setShowViewMetierModal(false);
  const closeViewCvModal = () => setShowViewCvModal(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { user, refreshUserData, updateUser, SnackBar } = useSessionContext();
  const { informSuccess } = SnackBar;

  const handleNotification = (notification) => () => {
    const { is_read } = notification;
    if (is_read) return;

    const raw = {
      user: {
        notification: notification.id,
        read_status: true,
      },
    };
    updateUser(raw).then(() => {
      refreshNotification();
      informSuccess('Notification lu!');
    });
  };

  const refreshNotification = () => {
    refreshUserData();
  };

  const deleteNotification = (notification) => () => {
    axios
      .delete(`${apiUrl()}/notifications/${notification.id}/`)
      .then((response) => {
        if (response.status === 200) {
          informSuccess('notification supprimée avec succès.');
          refreshNotification();
        }
      })
      .catch((error) => errorHandling(error));
    handleClose();
  };

  const handleOpenViewCVModal = (notification) => () => {
    const { user_metier, notification_metier_id, metier } = notification;

    setCvData({
      metierId: notification_metier_id,
      userMetierId: user_metier,
      metierName: metier,
    });

    setShowViewCvModal(true);
  };

  const handleOpenViewMetierModal = (notification) => () => {
    if(notification.type=='metier'){
      const {
        user_metier,
        notification_metier_id,
        metier,
        user_metier_enterprise,
      } = notification;
      if (user.type_of_user === 'Candidate') {
        setMetierData({
          metierId: notification_metier_id,
          userMetierId: user_metier_enterprise,
          metierName: metier,
          type: 'metier'
        });
      } else {
        setMetierData({
          metierId: notification_metier_id,
          userMetierId: user_metier,
          metierName: metier,
          type: 'metier'
        });
      }
      setShowViewMetierModal(true);
    }else if(notification.type=='pret'){
      const {
        applicant_metier,
        notification_metier_id,
        metier,
        provider_metier,
      } = notification;
      if (user.enterprise_info.providing_loan) {
        setMetierData({
          metierId: notification_metier_id,
          userMetierId: applicant_metier,
          metierName: metier,
          type: 'pret'
        });
      } else if (user.enterprise_info.seeking_loan) {
        setMetierData({
          metierId: notification_metier_id,
          userMetierId: provider_metier,
          metierName: metier,
          type: 'pret'
        });
      }
      setShowViewMetierModal(true);
    }
    
  };

  const confirmationBoxDeleteNotification = (notification) => (
    <div>
      <Tooltip title="Supprimer la notification">
        <span>
          <Fab color="secondary" aria-label="view" onClick={handleClickOpen}>
            <DeleteIcon />
          </Fab>
        </span>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <form>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 0',
                }}
              >
                <Fab color="secondary" aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <DialogTitle style={{ textAlign: 'center' }}>
                  Désirez-vous supprimer cette notification ?
                </DialogTitle>
              </Grid>
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Button
                  className={classes.reverseButtonStyle}
                  onClick={deleteNotification(notification)}
                >
                  Confirmer
                </Button>
                <Button
                  onClick={handleClose}
                >
                  Annuler
                </Button>
              </DialogActions>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );

  const { is_subscribed, type_of_user, notifications, first_name } = user;

  const notificationEnterprise = (notification) => {
    const { is_read } = notification;
    const { notification_kind } = notification;
    if (
      notification_kind === 'candidate_not_interested_answer' || notification_kind === 'partner_to_enterprise_not_interested_answer' || notification_kind === 'applicant_not_interested_answer'
      || notification_kind === 'provider_not_interested_answer'
    ) {
      return confirmationBoxDeleteNotification(notification);
    }
    if (is_subscribed) {
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={is_subscribed}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
    if (!is_subscribed && notification.answers.length>0){
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={false}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
  };

  const notificationPartner = (notification) => {
    const { is_read } = notification;
    const { notification_kind } = notification;

    const isNotificationKindNotInterestedAnswer =
      notification_kind === 'candidate_to_partner_not_interested_answer' ||
      notification_kind === 'enterprise_to_partner_not_interested_answer';

    const isNotificationKindtInterestedAnswer =
      notification_kind === 'candidate_to_partner_interested_answer' ||
      'partner_to_candidate_interest' ||
      notification_kind === 'partner_candidate_interest' ||
      notification_kind === 'partner_to_enterprise_interest' ||
      notification_kind === 'enterprise_to_partner_interested_answer';

    if (isNotificationKindNotInterestedAnswer && is_subscribed) {
      return confirmationBoxDeleteNotification(notification);
    }
    if (isNotificationKindNotInterestedAnswer && !is_subscribed) {
      return confirmationBoxDeleteNotification(notification);
    }
    if (is_subscribed) {
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={is_subscribed}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
    if (isNotificationKindtInterestedAnswer && !is_subscribed) {
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={false}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
  };

  const notificationCandidat = (notification) => {
    const { answers } = notification;

    if (
      type_of_user === 'Candidate' &&
      (notification.is_enterprise_subscribed ||
        notification.is_partner_subscribed)
    ) {
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={false}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
    if (answers.length > 0) {
      return (
        <FormMessageSender
          notification={notification}
          iSubscribed={false}
          typeOfUser={type_of_user}
          refreshNotification={refreshNotification}
        />
      );
    }
    return confirmationBoxDeleteNotification(notification);
  };

  const renderViewCvButton = (notification) => {
    if (type_of_user === 'Enterprise' && is_subscribed && notification.metier && notification.type=='metier') {
      return (
        <span id="2">
          <Tooltip title="Consulter le CV du candidat">
            <Fab
              color="secondary"
              aria-label="view"
              onClick={handleOpenViewCVModal(notification)}
            >
              <DescriptionIcon />
            </Fab>
          </Tooltip>
        </span>
      );
    }
    if (type_of_user === 'Enterprise' && !is_subscribed) {
      return (
        <span>
          <Tooltip title="Veuiller vous abonner pour consulter le CV du candidat">
            <span>
              <Fab color="secondary" disabled aria-label="view">
                <DescriptionIcon />
              </Fab>
            </span>
          </Tooltip>
        </span>
      );
    }
  };

  const renderNotificationCards = () => {
    if (notifications.length === 0) {
      return `Bonjour ${first_name}, vous n'avez pas de nouvelle notification`;
    }
    return notifications.map((notification, index) => {
      const { id, message, customize_message, created_at, is_read, contact_type } =
        notification;
      const labelId = `checkbox-list-label-${index}`;
      const timeString = `Recu le: ${created_at}`;
      const idString = `Match #${notification.id}`;
      const senderName =
        notification.is_enterprise_subscribed ||
        notification.is_partner_subscribed
          ? `Nom du contact: ${notification.sender_full_name}, `
          : '';
      const senderEmail =
        notification.is_enterprise_subscribed ||
        notification.is_partner_subscribed
          ? `Courriel du contact: ${notification.sender_email}`
          : '';

      const senderWebsite =
          notification.is_enterprise_subscribed ||
          notification.is_partner_subscribed
            ? `, Site web du contact: ${notification.contact_website}`
            : '';
      const msgexterne = typeof message === 'string' ? message : '';
      const customizeMessageExterne =
        typeof customize_message === 'string' ? customize_message : '';
      const renderMessage = (msg) => {
        if (msg !== null && msg !== undefined && typeof msg === 'string') {
          return (
            <ListItemText
              id={labelId}
              primary={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: msg.replace(/\n/g, '<br/>'),
                    }}
                  />
              }
              className={classes.listText}
            />
          );
        }
        return (
          <ListItemText
            id={labelId}
            primary={<div>"Une erreur est survenue"</div>}
            className={classes.listText}
          />
        );
      };

      return (
        <ListItem
          key={`notification-${id}`}
          role={undefined}
          dense
          onClick={handleNotification(notification)}
          className={`${classes.listItem} ${is_read ? classes.viewedItem : ''}`}
        >
          <ListItemIcon>
            <Checkbox
              id={`read-${id}`}
              edge="start"
              checked={is_read}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <div
            style={{
              display: 'block',
              flexDirection: 'row',
            }}
          >
            <Chip
                                      className={classes.chipLabel}
                                      icon={<LabelIcon />}
                                      label={contact_type}
                                      size='small'
                                      color='secondary'
                                      variant='outline'
                                  />
                                  <br/>
            {notification.type == 'service' && (
              <Chip
                  className={classes.chipLabelService}
                  icon={<InfoIcon />}
                  label={notification.service}
                  size='small'
                  color='primary'
                  variant='outline'
              />
            )}
            <ListItemText
              id={labelId}
              primary={idString}
              className={classes.listTextTime}
            />
            <ListItemText
              id={labelId}
              primary={timeString}
              secondary={senderName + senderEmail + senderWebsite}
              className={classes.listTextTime}
            />
            
            {renderMessage(msgexterne + customizeMessageExterne)}
            
            <div
              style={{
                position: 'relative',
                left: '-16px',
              }}
            >
              {type_of_user === 'Enterprise' ||
                (type_of_user === 'Partner' && !is_subscribed && (
                  <DisplayBannerButton />
                ))}
            </div>
          </div>

          <ListItemSecondaryAction className={classes.listItemSecondary}>
            {type_of_user === 'Enterprise' &&
              notificationEnterprise(notification)}
            {type_of_user === 'Candidate' && notificationCandidat(notification)}
            {type_of_user === 'Partner' && notificationPartner(notification)}
            {notification.type=='metier' && (
              <Tooltip
                title={`Voir la fiche métier ${
                  type_of_user === 'Candidate'
                    ? "de l'entreprise"
                    : 'du candidat'
                }`}
              >
                <span>
                  <Fab
                    color="secondary"
                    aria-label="add"
                    onClick={handleOpenViewMetierModal(notification)}
                  >
                    <VisibilityIcon />
                  </Fab>
                </span>
              </Tooltip>
            )}
            {notification.type=='pret' && (
              <Tooltip
                title={`Voir la fiche métier`}
              >
                <span>
                  <Fab
                    color="secondary"
                    aria-label="add"
                    onClick={handleOpenViewMetierModal(notification)}
                  >
                    <VisibilityIcon />
                  </Fab>
                </span>
              </Tooltip>
            )}
            {renderViewCvButton(notification)}
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <Grid container>
      <Card square className={classes.rootCard} elevation={0}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <CardHeader
              title="Notifications"
              className={classes.profileTabTitle}
            />
          </Grid>
        </Grid>
      </Card>

      <Card className={classes.rootCard} square elevation={1}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                {renderNotificationCards()}
              </List>
              {showViewMetierModal && (
                <JobModal
                  closeModal={closeViewEditModal}
                  metierData={metierData}
                  show={showViewMetierModal}
                  viewMetier={metierData.type=='metier'? true : false}
                  viewPret={metierData.type=='pret'? true : false}
                  viewProfile={true}
                />
              )}
              {showViewCvModal && (
                <ViewCv
                  closeModal={closeViewCvModal}
                  cvData={cvData}
                  show={showViewCvModal}
                  viewCv
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Notifications;
