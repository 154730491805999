import React, { useState } from 'react';
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  avatar: {
    width: '4em',
    height: '4em',
    position: 'relative',
    cursor: 'pointer',
  },
  uploadInput: {
    display: 'none',
  },
  cameraIcon: {
    position: 'absolute',
    left: '55%',
    top: '60%',
    width: '30px',
    height: '30px',
    borderRadius: '40px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const ModifyAvatar = () => {
  const {
    user,
    refreshUserData,
    updateUser
  } = useSessionContext();

  const { profile_pic } = user;
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  
  const classes = useStyles();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        updateUser({ user: { avatar: file } })
          .then((response) => {
            if (response.status === 200) {
              refreshUserData();
            } else {
              setErrors({ profile_pic: 'Failed to upload image' });
            }
          });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        src={image || profile_pic}
      />
      
      <input accept="image/*" className={classes.uploadInput}  onChange={handleImageUpload} id="icon-button-file" type="file" />
      <label htmlFor="icon-button-file">
        <Typography
          variant="caption"
          style={{
            position: 'absolute',
            left: '55%',
            top: '60%',
            width: '30px',
            height: '30px',
            borderRadius: '40px',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CameraAltIcon
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer'
            }}
            color="secondary"
          />
        </Typography>
      </label>
      {errors.profile_pic && (
        <span className={classes.error}>
          {errors.profile_pic}
        </span>
      )}
    </div>
  );
};

export default ModifyAvatar;
