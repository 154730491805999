import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getCitiesSearch } from '../Functions/Get-query/getCitiesSearch';

const useStyles = makeStyles(() => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const parseFromElasticSearchResults = (results) => {
  const parsedCitiesArray = [];
  results.forEach((res) => {
    parsedCitiesArray.push({
      loc_id: res._source.id,
      city_name: res._source.city,
      region1_name: res._source.admin_name,
      country_name: res._source.country,
      country_name_fr: res._source.country_fr
    });
  });
  return parsedCitiesArray;
};

const CountryAutoComplete = ({
  name,
  label,
  multiple = true,
  max = 3,
  onFormChange,
  formData,
  errors,
  locationsSelected,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  const onTextChanged = (event) => {
    const query = event.target.value;
    const destinationToRemove = [];
    let listDestinationsWithOutAlreadySelected = null;

    if (query === null || query === '') {
      if (countryList !== []) setCountryList([]);
      setOpen(false);
      return;
    }
    setLoading(true);

    getCitiesSearch(query)
      .then((response) => {
        if ( isObject(response) ) {
          if (locationsSelected) {
            locationsSelected.forEach((element) => {
              destinationToRemove.push(element.id);
            });
            listDestinationsWithOutAlreadySelected = response.results
              .filter((destination) => !destinationToRemove.includes(destination._source.id));
          } else if (formData.destinations !== null && formData.destinations !== undefined) {
            formData.destinations.forEach((element) => {
              destinationToRemove.push(element);
            });
            listDestinationsWithOutAlreadySelected = response.results
              .filter((destination) => !destinationToRemove.includes(destination._source.id));
          } else if (formData.locations !== null && formData.locations !== undefined) {
            formData.locations.forEach((element) => {
              destinationToRemove.push(element);
            });
            listDestinationsWithOutAlreadySelected = response.results
              .filter((destination) => !destinationToRemove.includes(destination._source.id));
          }

          if (response.total > 0) {
            const newListToParse = listDestinationsWithOutAlreadySelected || response.results;
            const newList = parseFromElasticSearchResults(newListToParse);
            setCountryList(newList.slice(0, 100));
            setOpen(true);
          } else {
            setOpen(false);
          }
        }
        setLoading(false);
      });
  };

  const getLocationDisplayName = (row) => {
    const cityName = row.city_name == null ? '' : `${row.city_name}, `;
    const region1Name = row.region1_name == null ? '' : `${row.region1_name}, `;
    const region2Name = row.region2_name == null ? '' : `${row.region2_name}, `;
    const countryName = row.country_name == null ? '' : `${row.country_name} `;
    const countryNameFr = row.country_name_fr == null ? '' : `${row.country_name_fr} `;
    const continentName = row.continent_name == null ? '' : row.continent_name;

    return `${cityName}${region2Name}${region1Name}${countryNameFr}${continentName}`;
  };

  const handleLocationSelection = (event, data) => {
    if (!multiple) {
      onFormChange({
        target: {
          name,
          value: data?.loc_id || null,
        },
      });
    } else if (data.length <= max) {
      if (data === null) {
        onFormChange({
          target: {
            name,
            value: [],
          },
        });
      } else {
        const destArray = data.map((d) => d.loc_id);
        setCountryData(data)
        onFormChange({
          target: {
            name,
            value: destArray,
          },
        });
      }
    }
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <>
      <Autocomplete
        id={name}
        multiple={multiple}
        limitTags={3}
        open={isOpen}
        noOptionsText="En chargement..."
        getOptionLabel={getLocationDisplayName}
        options={countryList}
        loading={isLoading}
        key={(option) => option.id}
        getOptionDisabled={() => countryData.length >= max ? true : false}
        onChange={handleLocationSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onTextChanged}
            onBlur={onTextChanged}
            error={!!errors[name]}
            variant="outlined"
            label={label}
            placeholder=""
          />
        )}
      />
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

CountryAutoComplete.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

CountryAutoComplete.defaultProps = {
  max: 3,
  multiple: false,
};

export default CountryAutoComplete;
