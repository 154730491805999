import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  AppBar,
  Tab,
  Tabs,
  Box,
  Hidden,
  Fab,
  CardContent,
  Button,
  Select,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import Modal from '@material-ui/core/Modal';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import InfoIcon from '@material-ui/icons/Info';
import ModifyInfo from './FormModal/modifyInfo';
import ModifyAvatar from './FormModal/ModifyAvatar';
import Destinations from './Destinations';
import Langues from './shared/Langues';
import Services from './Partner/Services';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import { TabPanel } from './TabPanel';
import Metiers from './Metiers';
import PretPersonnel from './PretPersonnel';
import AlertComponent from '../AlertComponent';
import Banner from '../DisplayBannerButton';
import Contracts from './Contracts';
import ServicesSelection from './ServicesSelection';
import UploadCV from '../../Form/FormSteps/Shared/UploadCV';
import PersonalInformation from './PersonalInformation';
import EmployeeCount from './EmployeeCountSelector';
import ListOfValues from './ListOfValues';
import PersonalityTest from './Personality';

const getLocationDisplayName = (worldCity) => {
  if (worldCity) {
    return `${worldCity.city}, ${worldCity.country_fr}`;
  }
  return 'Une ville, Un pays';
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& span': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
  },

  profilTypo: {
    textAlign: 'left',
  },
  profilCard: {
    padding: '15px',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      '&:first-of-type': {
        order: 0,
        alignItems: 'flex-start',
        paddingLeft: 20,
      },
      '&:nth-of-type(2)': {
        order: 2,
        padding: 20,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-of-type(2)': {
        order: 3,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    '& a': {
      color: '#000!important',
    },
  },
  expTabs: {
    '& button': {
      color: '#000',
      width: 'fit-content',
    },
    '& > span': {
      height: 5,
    },
  },
  infoIcon: {
    alignSelf: 'flex-start',
    marginTop: '10px',
  },
  infoLink: {
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  player: {
    height: '100%',
    width: '100%',
  },
  modalCard: {
    marginTop: '5vw',
    aspectRatio: '16/9',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  infoJobsBox: {
    width: '100%',
    marginTop: '2vh',
  },
  cVBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 4,
    textAlign: 'center',
  },
  infoJobsCard: {
    backgroundColor: theme.palette.secondary.main,
  },
  connexionCard: {
    backgroundColor: 'white',
  },
  infoConnexionCardText: {
    color: 'black',
  },
  infoJobsText: {
    color: theme.palette.secondary.secondary,
  },
  submitCurriculumButton: {
    marginTop: '1rem !important',
    marginBottom: '3vh !important',
  },
  disabledButton: {
    backgroundColor: 'grey',
  },
}));

const UserProfile = () => {
  const classes = useStyles(theme);
  const { user, updateUser, refreshUserData, SnackBar } = useSessionContext();
  const [value, setValue] = React.useState(0);
  const { informSuccess, informError } = SnackBar;
  const [profileInfo, setProfileInfo] = React.useState({
    typeOfUser: '',
    profilePic: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    enterpriseName: '',
    jobs: [],
    languages: [],
    baseCountry: [],
    destinations: [],
    website: '',
    baseLocation: [],
    partner_locations: [],
    partner_services: [],
    isJobImported: false,
    numberCandidatesOrEnterprises: 0,
    metierWithMostCandidatesOrEnterprises: '',
    isSubscribed: 0,
    subscribedTo: {},
    linkedCv: [],
    cvFilename: null,
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);

  const handleModalOpen = (event) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChange = function (event, newValue) {
    setValue(newValue);
  };
  const modalBody = (
    <>
      <Card className={classes.modalCard}>
        <CardActionArea className={classes.player}>
          <CardMedia
            component="iframe"
            alt="Tutorial moovejob"
            width="100%"
            height="100%"
            image="https://www.youtube.com/embed/IjZ4mivU8to"
            autoPlay
            controls
            allowfullscreen="allowfullscreen"
            title="Tutorial moovejob"
          />
        </CardActionArea>
      </Card>
    </>
  );

  const infoNumberOfCandidatesOrEnterprises = {
    Candidate: `Il y a ${profileInfo.numberCandidatesOrEnterprises} ${
      profileInfo.numberCandidatesOrEnterprises.length > 1
        ? 'entreprises'
        : 'entreprise(s)'
    } à la recherche du métier ${
      profileInfo.metierWithMostCandidatesOrEnterprises
    }`,
    Enterprise: `Il y a ${profileInfo.numberCandidatesOrEnterprises} ${
      profileInfo.numberCandidatesOrEnterprises.length > 1
        ? 'candidats'
        : 'candidat(s)'
    } à la recherche du métier ${
      profileInfo.metierWithMostCandidatesOrEnterprises
    }`,
  };

  const infoNoCandidatesOrEnterprises = {
    Candidate:
      "Pour le moment, il n'y a aucune entreprise  à la recherche d'une des professions choisies.",
    Enterprise:
      "Pour le moment, il n'y a pas de candidats à la recherche d'une des professions choisies.",
  };

  useEffect(() => {
    const otherUserData = () => {
      let otherData = {};
      switch (user.type_of_user) {
        case 'Candidate':
          otherData = {
            baseLocation: user.candidate_location,
            jobs: user.job_experience,
            destinations: user.candidate_destinations,
            metiers: user.metiers,
            matches: user.matches,
            linkedCv: user.linked_cv ?? [],
            cvFilename: user.cv_filename,
          };
          break;
        case 'Enterprise':
          otherData = {
            enterpriseName: user.enterprise_name,
            website: user.website,
            jobs: user.job_locations,
            destinations: user.job_locations,
            metiers: user.metiers,
            matches: user.matches,
            isSubscribed: user.is_subscribed,
            subscribedTo: user.subscribed_to,
          };
          break;
        case 'Partner':
          otherData = {
            enterpriseName: user.enterprise_name,
            website: user.website,
            partner_locations: user.partner_locations,
            partner_services: user.user_services,
          };
      }
      return otherData;
    };
    const userInfo = {
      id: user.id,
      typeOfUser: user.type_of_user,
      profilePic: user.profile_pic,
      fullName: user.full_name,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      languages: user.languages,
      isJobImported: user.is_job_imported,
      numberCandidatesOrEnterprises: user.number_candidates_or_enterprises,
      metierWithMostCandidatesOrEnterprises:
        user.metier_with_most_candidates_or_enterprises,
      ...otherUserData(),
    };
    setProfileInfo(userInfo);
  }, [user]);

  const alertComponent = () => {
    const lack = { metier: false, location: false };

    if (profileInfo.typeOfUser === 'Candidate') {
      if (user.metiers.length === 0) {
        lack.metier = true;
      }
      if (profileInfo.destinations.length === 0) {
        lack.location = true;
      }
    }

    if (profileInfo.typeOfUser === 'Enterprise') {
      if (user.metiers.length === 0) {
        lack.metier = true;
      }
      if (profileInfo.destinations.length === 0) {
        lack.location = true;
      }
    }

    if (lack.metier || lack.location) {
      return <AlertComponent lack={lack} />;
    }
  };

  const onFormChange = (event) => {
    setProfileInfo({
      ...profileInfo,
      [event.target.name]: event.target.value,
    });
    setClickedUpload(true);
  };

  const handleSubmit = () => {
    if (profileInfo.linkedCv.size > 0) {
      const raw = {
        user: {
          cv_file: profileInfo.linkedCv,
        },
      };

      updateUser(raw).then((response) => {
        if (response.status === 400) {
          if (response.data.error) {
            setFormErrors({
              ...errors,
              verifyAll: true,
            });
            informError(errors);
          }
        }
        if (response.status === 200) {
          refreshUserData();
          informSuccess('CV mis à jour avec succès');
        }
      });
    } else {
      informError('Veuillez sélectionner votre cv avant de soummettre');
      setClickedUpload(false);
    }
    setClickedUpload(false);
  };

  return (
    <Grid container>
      {alertComponent()}
      <AppBar
        position="relative"
        className={classes.menuTabs}
        style={{ backgroundColor: '#f9f9f9' }}
      >
        <Grid
          container
          style={{
            padding: '20px 0 20px',
          }}
        >
          <Grid item xs={9} md={3} className={classes.flexCenter}>
            <Box position="relative">
              <ModifyAvatar currentImage={profileInfo.profilePic} />
            </Box>
          </Grid>

          <Grid item md={6} xs={12} className={classes.flexCenter}>
            <Typography variant="body2">
              {profileInfo.typeOfUser === 'Candidate' ? (
                <> {profileInfo.fullName}</>
              ) : (
                <>{profileInfo.enterpriseName}</>
              )}
            </Typography>
            {profileInfo.typeOfUser !== 'Partner' ? (
              <>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', marginLeft: '-5px' }}
                >
                  {profileInfo.typeOfUser === 'Candidate' && (
                    <RoomIcon color="secondary" />
                  )}
                  {profileInfo.typeOfUser === 'Candidate'
                    ? getLocationDisplayName(profileInfo.baseLocation)
                    : ''}
                </Typography>
              </>
            ) : null}
            <Typography style={{ width: '100%' }}>
              <a href={`mailto:${profileInfo.email}`}>{profileInfo.email}</a>
            </Typography>
            {profileInfo.typeOfUser !== 'Candidate' && (
              <Typography style={{ width: '100%', marginTop: 10 }}>
                <a href={profileInfo.website}>{profileInfo.website}</a>
              </Typography>
            )}
            <EmployeeCount />
            {profileInfo.typeOfUser !== 'Partner' && (
              <Box className={classes.infoJobsBox}>
                <Card className={classes.infoJobsCard}>
                  <CardContent>
                    <Typography className={classes.infoJobsText}>
                      {profileInfo.numberCandidatesOrEnterprises > 0
                        ? infoNumberOfCandidatesOrEnterprises[
                            profileInfo.typeOfUser
                          ]
                        : infoNoCandidatesOrEnterprises[profileInfo.typeOfUser]}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            )}
            <Box className={classes.infoJobsBox}>
              <Card className={classes.connexionCard}>
                <CardContent>
                  <Typography className={classes.infoConnexionCardText}>
                    Vous avez eu depuis votre création de compte {user.number_of_matches} connexions
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            {profileInfo.typeOfUser === 'Candidate' && (
              <Box className={classes.cVBox}>
                <UploadCV
                  userType={profileInfo.typeOfUser}
                  formData={profileInfo}
                  onFormChange={onFormChange}
                />
                {profileInfo.linkedCv && (
                  <div style={{ paddingBottom: '10px' }}>
                    <a
                      href={profileInfo.linkedCv}
                      target="_blank"
                      className={classes.curriculumLink}
                    >
                      {profileInfo.cvFilename}
                    </a>
                  </div>
                )}
                {clickedUpload === true ? (
                  <div>
                    <Button
                      id="submitCV"
                      onClick={handleSubmit}
                      className={classes.submitCurriculumButton}
                    >
                      <span
                        style={{
                          fontSize: '1.5rem',
                          textTransform: 'none',
                          fontFamily: 'Dosis,"Helvetica Neue",Arial,sans-serif',
                          fontWeight: '500',
                          textAlign: 'center',
                        }}
                      >
                        2. Soumettre
                      </span>
                    </Button>
                  </div>
                ) : null}
              </Box>
            )}
          </Grid>

          <Grid item xs={3} md={3} className={classes.flexCenter}>
            <ModifyInfo userInfo={profileInfo} />
          </Grid>
        </Grid>
        <Grid container style={{ paddingBottom: 20 }}>
          <Grid item xs={12}>
            <Hidden lgUp>
              <Banner />
            </Hidden>
          </Grid>
        </Grid>
        <Tabs
          className={classes.expTabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          {profileInfo.typeOfUser === 'Candidate' ? (
            !profileInfo.isJobImported && (
              <Tab
                className="editJobs"
                aria-label="Metiers"
                label="Metiers"
                {...a11yProps(0)}
              />
            )
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Tab
              className="editJobs"
              aria-label="Offres d'emploi"
              label="Offres d'emploi"
              {...a11yProps(0)}
            />
          ) : (
            <Tab
              className="editServices"
              aria-label="Services offerts"
              label="Services offerts"
              {...a11yProps(0)}
            />
          )}
          <Tab
            className="editLanguages"
            aria-label="Langues"
            label="Langues"
            {...a11yProps(1)}
          />
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Tab
              className="editDestinations"
              aria-label="Destinations"
              label="Destinations"
              {...a11yProps(2)}
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Tab
              className="editLocations"
              aria-label="Localisations"
              label="Localisations"
              {...a11yProps(2)}
            />
          ) : profileInfo.typeOfUser === 'Partner' ? (
            <Tab
              className="editLocations"
              aria-label="Localisations"
              label="Localisations"
              {...a11yProps(2)}
            />
          ) : null}

          {profileInfo.typeOfUser === 'Candidate' ? (
            <Tab
              className="editContract"
              aria-label="Contrats"
              label="Contrats"
              {...a11yProps(3)}
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Tab
              className="editContract"
              aria-label="Contrats"
              label="Contrats"
              {...a11yProps(3)}
            />
          ) : null}
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Tab
              className="editServicesSelection"
              aria-label="ServicesSelect"
              label="Services"
              {...a11yProps(4)}
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Tab
              className="editServicesSelection"
              aria-label="ServicesSelect"
              label="Services"
              {...a11yProps(4)}
            />
          ) : null}
          {profileInfo.typeOfUser != 'Partner' && (
            <Tab
              className="listOfValues"
              aria-label="listOfValues"
              label="Liste de Valeurs"
              {...a11yProps(5)}
            />
          )}
          {profileInfo.typeOfUser === 'Candidate' && (
            <Tab
              className="candidatePersonalInformation"
              aria-label="CandidatePersonalInformation"
              label="Informations personnelles"
              {...a11yProps(6)}
            />
          )}
          {profileInfo.typeOfUser === 'Candidate' && (
            <Tab
              className="candidatePersonality"
              aria-label="candidatePersonality"
              label="Médias sociaux"
              {...a11yProps(7)}
            />
          )}
          {profileInfo.typeOfUser === 'Enterprise' && (
            <Tab
              className="enterpriseLoan"
              aria-label="enterpriseLoan"
              label="Prêt de personnel"
              {...a11yProps(6)}
            />
          )}
        </Tabs>
      </AppBar>
      <AppBar position="relative" className={classes.menuTabs} square>
        <TabPanel value={value} index={0}>
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Metiers
              isImported={profileInfo.isJobImported}
              jobs={profileInfo.jobs}
              metiers={profileInfo.metiers}
              isSubscribed
              subscribedTo=""
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Metiers
              metiers={profileInfo.metiers}
              isImported={profileInfo.isJobImported}
              jobs={profileInfo.jobs}
              typeOfUser="Enterprise"
              isSubscribed={profileInfo.isSubscribed}
              subscribedTo={profileInfo.subscribedTo.name}
            />
          ) : profileInfo.typeOfUser === 'Partner' ? (
            <Services services={profileInfo.services} />
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Langues
            typeOfUser={profileInfo.typeOfUser}
            languages={profileInfo.languages}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Destinations
              typeOfUser={profileInfo.typeOfUser}
              locationInfo={profileInfo.destinations}
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Destinations
              typeOfUser={profileInfo.typeOfUser}
              locationInfo={profileInfo.destinations}
            />
          ) : profileInfo.typeOfUser === 'Partner' ? (
            <Destinations
              typeOfUser={profileInfo.typeOfUser}
              locationInfo={profileInfo.services_locations}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Contracts typeOfUser={profileInfo.typeOfUser} />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <Contracts typeOfUser={profileInfo.typeOfUser} />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {profileInfo.typeOfUser === 'Candidate' ? (
            <ServicesSelection
              typeOfUser={profileInfo.typeOfUser}
              locationInfo={profileInfo.destinations}
            />
          ) : profileInfo.typeOfUser === 'Enterprise' ? (
            <ServicesSelection
              typeOfUser={profileInfo.typeOfUser}
              locationInfo={profileInfo.destinations}
            />
          ) : null}
        </TabPanel>
        
        <TabPanel value={value} index={5}>
          <ListOfValues />
        </TabPanel>
        {profileInfo.typeOfUser === 'Candidate' && (
            <>
              <TabPanel value={value} index={6}>
                <PersonalInformation />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <PersonalityTest />
              </TabPanel>
            </>
        )}
        {profileInfo.typeOfUser === 'Enterprise' && (
          <TabPanel value={value} index={6}>
            <PretPersonnel
              metiers={profileInfo.metiers}
              isImported={profileInfo.isJobImported}
              jobs={profileInfo.jobs}
              typeOfUser="Enterprise"
              isSubscribed={profileInfo.isSubscribed}
              subscribedTo={profileInfo.subscribedTo.name}
            />
          </TabPanel>
        )}
      </AppBar>
    </Grid>
  );
};

export default UserProfile;
