import { TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PasswordField from 'material-ui-password-field';
import theme from '../theme';

const useStyles = makeStyles(() => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#37383e',
    cursor: 'pointer',
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
}));

const PasswordCreation = ({ errors, setErrors, formData, onFormChange }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6}>
        <PasswordField
          className={classes.passwordField}
          placeholder="Votre mot de passe"
          required
          min={6}
          error={!!errors.password}
          name="password"
          label="Mot de passe"
          type="password"
          id="new-password"
          onChange={(event) => onFormChange(event)}
          onBlur={(event) => onFormChange(event)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PasswordField
          className={classes.passwordField}
          placeholder="Votre mot de passe"
          required
          min={6}
          error={!!errors.confirmPassword}
          name="confirmPassword"
          label="Confirmer le mot de passe"
          type="password"
          id="confirm-password"
          onChange={(event) => onFormChange(event)}
          onBlur={(event) => onFormChange(event)}
          fullWidth
        />
      </Grid>
      {errors.password && (
        <p className={`${classes.error} ${'error'}`}>{errors.password}</p>
      )}
    </>
  );
};

PasswordCreation.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default PasswordCreation;
