import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator/es';
import { useInput } from '../Hooks/useInput';
import Footer from '../UI-Component/Footer';
import LocationStep from './FormSteps/Shared/LocationStep';
import LanguageStep from './FormSteps/Shared/LanguageStep';
import WebsiteStep from './FormSteps/Shared/WebsiteStep';
import JobsStep from './FormSteps/Shared/JobsStep';
import ServiceStep from './FormSteps/Partner/ServiceStep';
import ProfileImageStep from './FormSteps/Shared/ProfileImageStep';
import DestinationsStep from './FormSteps/Candidate/DestinationsStep';
import PasswordCreation from './PasswordCreation';
import LoaderSpinner from '../UI-Component/LoaderSpinner';
import useSessionContext from '../sessions/useSessionContext';
import ReCaptcha from './ReCaptcha';
import UploadCV from './FormSteps/Shared/UploadCV';

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
  },
  bgHero: {
    position: 'relative',
    top: '150px',
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  emailCheck: {
    margin: '2vh 0',
    display: 'flex',
    alignItems: 'baseline',
    textAlign: 'left',
    '&:first-of-type  span': {
      position: 'relative',
      top: '3px',
    },
  },

  consentCheck: {
    margin: '2vh 0',
    display: 'flex',
    alignItems: 'baseline',
    textAlign: 'left',
    '&:first-of-type  span': {
      position: 'relative',
      top: '3px',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow:'0 0 0 .5px rgba(50,50,93,.1),0 2px 5px 0 rgba(50,50,93,.1),0 1px 1.5px 0 rgba(0,0,0,.07)',
    borderRadius:'7px',
    padding: '20px'
  },
  '& > *': {
    zIndex: 100,
    position: 'relative',
  },

  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  html: {
    height: '100%',
    margin: 0,
    scollBehavior: 'smooth',
  },
  introTitle:{
    fontWeight: '600',
    fontSize: '1em',
    color: 'black'
  },
  introLabel:{
    '& > span': {
      fontSize: '1em',
      color: 'black'
    },

  },
  introListItem:{
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: '30%'
      },
    },
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      padding: '0px',
      '& div.MuiListItemIcon-root': {
        width: '100%'
      },
    }
  },
  containerForm:{
    marginTop: '25px',
  },
  slogan:{
    fontSize: '1.5em'
  },
}));

const BaseForm = (props) => {
  const { userType } = props;
  const [isGlobal, setIsGlobal] = useState(false);
  const [isEmailOptIn, setEmailOptIn] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [image, setImage] = useState([]);
  const [curriculum, setCurriculum] = useState(null);

  const [services, setServices] = useState([]);

  const { value: website } = useInput(
    '',
  );

  const history = useHistory();

  function navigate(path, data) {
    history.push(path, data);
  }

  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const handleRecaptchaResponse = (r) => {
    setRecaptchaResponse(r);
  };

  useEffect(() => {
    if (process.env.NO_CAPTCHA) {
      setRecaptchaResponse(-1);
    }
  }, []);
  const {
    createUser,
    setShowNewsLetterMaster
  } = useSessionContext();
  useEffect(() => {
    setShowNewsLetterMaster(false);
    return () => setShowNewsLetterMaster(true);
  }, []);

  const getLocationDisplayName = (row) => {
    const cityName = row.city_name == null ? '' : `${row.city_name}, `;
    const region1Name = row.region1_name == null ? '' : `${row.region1_name}, `;
    const countryName = row.country_name == null ? '' : `${row.country_name} `;

    return `${cityName}${region1Name}${countryName}`;
  };

  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    enterpriseName: null,
    locations: null,
    website: null,
    country: null,
    languages: null,
    profile_pic: null,
    linkedCv: null,
    destinations: null,
    jobs: null,
    password: null,
    confirmPassword: null,
    showSpinner: false,
    user_services: null,
    partner_locations: null,
    confirmEmail: null,
    byWorkType: false,
    workType: null,
    consent: false
  });
  const validateForm = (verifyAll = false) => {
    const errors = {};

    /* Share */
    if (formData.password || verifyAll) {
      if (formData.password === null) {
        errors.password = 'Un mot de passe est requis';
      } else if (!validator.isLength(formData.password, {
        min: 8,
        max: 25,
      })) {
        errors.password = 'Le mot de passe doit contenir au minimum 8 caractères';
      } else if (formData.confirmPassword !== formData.password) {
        errors.password = 'Les mots de passe ne correspondent pas';
      }
    }

    if (formData.confirmPassword || verifyAll) {
      if (formData.confirmPassword === null) {
        errors.confirmPassword = 'Un mot de passe est requis';
      } else if (
        !validator.isLength(formData.confirmPassword, {
          min: 8,
          max: 25,
        })
      ) {
        errors.confirmPassword = 'Le mot de passe doit contenir au minimum 8 caractères';
      } else if (formData.confirmPassword !== formData.password) {
        errors.password = 'Les mots de passe ne correspondent pas';
      }
    }

    if (formData.firstName || verifyAll) {
      if (formData.firstName === null) {
        errors.firstName = 'Le prénom est requis. (entre 2 et 15 caractères)';
      } else if (!validator.isLength(formData.firstName, {
        min: 2,
        max: 15,
      })) {
        errors.firstName = 'Le prénom n\'est pas valide. (entre 2 et 15 caractères)';
      }
    }

    if (formData.lastName || verifyAll) {
      if (formData.lastName === null) {
        errors.lastName = 'Le nom de famille est requis. (entre 2 et 15 caractères)';
      } else if (!validator.isLength(formData.lastName, {
        min: 2,
        max: 15,
      })) {
        errors.lastName = 'Le nom de famille n\'est pas valide. (entre 2 et 15 caractères)';
      }
    }

    if (formData.email || verifyAll) {
      if (formData.email === null) {
        errors.email = 'L\'adresse courriel est requise. (votrecourriel@email.com)';
      } else if (!validator.isEmail(formData.email)) {
        errors.email = 'L\'adresse courriel n\'est pas valide. (votrecourriel@email.com)';
      }
    }

    if (formData.confirmEmail || verifyAll) {
      if (formData.confirmEmail === null) {
        errors.confirmEmail = `L'adresse courriel inscrite doit correspondre à la précédente`;
      } else if (formData.confirmEmail !== formData.email) {
        errors.confirmEmail = 'L\'adresse courriel ne correspond pas';
      }
    }

    if (formData.languages || verifyAll) {
      if (formData.languages === null) {
        errors.languages = 'Vous devez choisir au moins une langue!';
      }
    }

    if (formData.profile_pic || verifyAll) {
      if (formData.profile_pic === null) {
        errors.profile_pic = 'Vous devez choisir une photo de profil!';
      }
    }

    if (userType === 'Candidate') {
      if (formData.linkedCv || verifyAll) {
        if (formData.linkedCv === null) {
          errors.linkedCv = 'Vous devez inserer votre CV!';
        }
      }
    }

    /* End Shared fields */

    /* Only Candidate */
    if (userType === 'Candidate') {
      if (formData.country || verifyAll) {
        if (formData.country === null) {
          errors.country = 'Le lieu de résidence est requis!';
        }
      }
      if (formData.destinations || verifyAll) {
        if (formData.destinations === null) {
          errors.destinations = 'Vous devez choisir au moins une destination!';
        }
      }
    }
    /* End User fields */

    /* Partner and enterprise */
    if (userType !== 'Candidate') {
      if (formData.enterpriseName || verifyAll) {
        if (formData.enterpriseName === null) {
          errors.enterpriseName = 'Le nom de l\'entreprise est requis. (entre 2 et 30 caractères)';
        } else if (
          !validator.isLength(formData.enterpriseName, {
            min: 2,
            max: 30,
          })
        ) {
          errors.enterpriseName = 'Le nom de l\'entreprise n\'est pas valide. (entre 2 et 30 caractères)';
        }
      }
      if (formData.website || verifyAll) {
        if (formData.website === null) {
          errors.website = 'L\'adresse de votre site web est requise. (https://votresite.com)';
        } else if (!validator.isURL(formData.website)) {
          errors.website = 'L\'adresse de votre site web n\'est pas valide. . (https://votresite.com)';
        }
      }

      if (formData.locations || verifyAll) {
        if (formData.locations === null && userType === 'Enterprise') {
          errors.locations = 'Vous devez choisir la localisation de(s) emploi(s) à combler.';
        } else if (formData.locations === null && userType === 'Partner' && !isGlobal) {
          errors.locations = 'Vous devez choisir la localisation des services offerts.';
        }
      }
    }

    if (userType !== 'Partner') {
      if (formData.jobs || verifyAll) {
        if (formData.jobs === null) {
          errors.jobs = 'Vous devez choisir au moins un emploi ainsi que le nombre d\'années d\'expérience relatif à l\'emploi sélectionné';
        }
      }
    }

    if (formData.consent || verifyAll) {
      if (formData.consent === null || !formData.consent) {
        errors.consent = 'Vous devez accepter les termes et conditions';
      }
    }

    setFormErrors({
      ...errors,
      verifyAll,
    });

    // console.info('Form errors', errors);
    return Object.keys(errors).length < 1;
  };

  // function to return to field with error

  useEffect(() => {
    if (Object.keys(formErrors).length > 0 && formErrors.verifyAll === true) {
      const items = document.getElementsByClassName('error') || [];
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      const visible = [...items].filter(
        (el) =>
          // jQuery-like :visible selector
          !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length),
      );

      if (visible.length > 0) {
        if (supportsNativeSmoothScroll) {
          window.scrollTo({
            top: items[0].offsetTop,
            behavior: 'smooth',
          });
        } else {
          window.scrollTo({
            top: items[0].offsetTop,
          });
        }
        setTimeout(() => {
          setFormErrors({
            ...formErrors,
            verifyAll: false,
          });
        }, 100);
      }
    }
  }, [formErrors]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value,
      },
      validateForm(),
    );
  };

  const handleLivingLocationSelection = (event, data) => {
    onFormChange({
      target: {
        name: ['country'],
        value: data?.loc_id || null,
      },
    });
  };

  const handleLanguages = (event, data) => {
    onFormChange({
      target: {
        name: 'languages',
        value: data?.id || null,
      },
    });
  };

  const handleConsent = (consentValue) => {
    setFormData(prevState => ({
      ...prevState,
      consent: consentValue === 'true'
    }));
    validateForm();
  };

  const validateConsent = () => {
    return (
      <Grid item xs={12}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.consent}
              onChange={(event) => handleConsent(event.target.value)}
              name="accept-terms"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              error={formErrors.consent}
              value={!formData.consent}
              required
            />
          )}
          label={
            <p>
              J’ai lu et j'accepte les conditions d'utilisation et la <a
              href={'/politique-de-confidentialite'} target={'_blank'}>politique de
              confidentialité</a>*
            </p>

          }
          className={classes.consentCheck}
        />
        {formErrors.consent && (
          <p style={{
            marginTop: '-20px',
            marginBottom: '30px'
          }} className={`${classes.error} ${'error'}`}>{formErrors.consent}</p>
        )}
      </Grid>
    );
  };

  const handleSubmit = () => {
    if (!validateForm(true)) {
      console.error('Unable to validate form', formErrors);
      return;
    }

    if (!recaptchaResponse) {
      // Possibly disable this in dev
      console.error('reCAPTCHA is not valid.');
      return;
    }
    const raw = {
      user: {
        type_of_user: userType,
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        job_location: formData.locations,
        enterprise_name: formData.enterpriseName,
        website: formData.website,
        base_country: formData.country,
        languages: formData.languages,
        destinations: formData.destinations,
        jobs: formData.jobs,
        opt_in: isEmailOptIn,
        cv_file: userType === 'Candidate' ? formData.linkedCv : null,
        is_global: isGlobal,
        password: formData.password,
        user_services: formData.services,
        partner_locations: formData.locations
      },
    };

    setFormData({
      ...formData,
      showSpinner: true,
    });
    raw.user.avatar = image[0].file;

    createUser(raw)
      .then((response) => {
        if (response != null && response != undefined) {
          if (response.status === 400) {
            if (response.data.error === 'Not Unique') {
              setFormErrors({
                email: response.data.message,
                verifyAll: true,
              });
            }
          }
          if (response.status === 200) {
            navigate('/confirmation', {
              email: raw.user.email,
              first_name: raw.user.first_name,
            });

          }
        }
        setFormData({
          ...formData,
          showSpinner: false,
        });
      });
  };

  const classes = useStyles();
  return (
    <main>
      {formData.showSpinner && (
        <LoaderSpinner sizeOfSpinner={150} text="Création en cours"/>
      )}
      <div className={classes.bgHero}>
        <Container maxWidth="md" className={classes.homeContainer}>
          <Box>
            

            <form className={classes.form}>
              {userType === 'Candidate' ? (
                <>
                  <Typography variant="h1">Je cherche des opportunités à l’international !</Typography>
                  <List dense>
                      <ListItem id='list-1' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                            Inscription Gratuite :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Connectez vous directement aux entreprises qui recherchent dans le cadre de votre
projet d’immigration, dans votre secteur et dans la région du monde que vous
souhaitez !"
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Algorithme Intelligent :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Des matchs automatiques basés sur vos besoins précis, vous faisant gagner du temps et évitant d'analyser des offres pendant des heures."
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Optimiser vos opportunités :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Une fois connecté à votre compte, remplissez tous les champs de votre profil, vous pouvez saisir jusqu'à trois métiers différents."
                          className={classes.introLabel}
                        />
                      </ListItem>
                  </List>
                </>
              ) : userType === 'Enterprise' ? (
                <>
                  <Typography variant="h1">Je cherche des candidats pour mon entreprise</Typography>
                  <List dense>
                      <ListItem id='list-1' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                            Inscription Gratuite :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Commencez sans frais et découvrez instantanément les compétences francophones
qualifiées que vous recherchez, selon le ou les contrats que vous proposez, jusqu&#39;à
cinq métiers différents."
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Algorithme Intelligent :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Des matchs automatiques basés sur vos besoins précis, vous faisant gagner du temps et évitant les recherches infructueuses."
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Maîtrise des coûts :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Accédez aux CV complets et contactez les candidats uniquement s' ils correspondent à vos critères. Abonnez-vous seulement lorsque vous avez vos premiers résultats !"
                          className={classes.introLabel}
                        />
                      </ListItem>
                  </List>
                </>
              ) : (
                <>
                  <Typography variant="h1">
                  J’offre des services aux entreprises et aux candidats en lien avec la mobilité internationale !
                  </Typography>
                  <List dense>
                      <ListItem id='list-1' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                            Inscription Gratuite :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Commencez sans frais et découvrez instantanément des correspondances avec des entreprises et des candidats francophones qui souhaitent être accompagnés dans le cadre de vos services."
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Algorithme Intelligent :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Des matchs automatiques basés sur les besoins précis des utilisateurs vous faisant gagner du temps."
                          className={classes.introLabel}
                        />
                      </ListItem>
                      <ListItem id='list-2' alignItems="flex-start" className={classes.introListItem}>
                        <ListItemIcon>
                          <Typography variant='caption' className={classes.introTitle}>
                          Maîtrise des coûts :
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          primary="Connectez vous seulement aux bons interlocuteurs pour présenter vos services. Accédez à de nouveaux clients ayant un réel intérêt d’être accompagné. Abonnez-vous seulement lorsque vous avez vos premiers résultats !"
                          className={classes.introLabel}
                        />
                      </ListItem>
                  </List>
                </>
              )}
              <Grid container spacing={2} className={classes.containerForm}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    error={!!formErrors.firstName}
                    autoFocus
                    required
                    fullWidth
                    id="firstName"
                    value={formData.firstName || ''}
                    label="Prénom"
                    autoComplete="given-name"
                    onChange={(event) => onFormChange(event)}
                    onBlur={(event) => onFormChange(event)}
                    name="firstName"
                  />
                  {formErrors.firstName && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.firstName}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    error={!!formErrors.lastName}
                    required
                    fullWidth
                    value={formData.lastName || ''}
                    label="Nom de famille"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(event) => onFormChange(event)}
                    onBlur={(event) => onFormChange(event)}
                  />
                  {formErrors.lastName && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.lastName}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={!!formErrors.email}
                    required
                    fullWidth
                    value={formData.email || ''}
                    label="Courriel"
                    name="email"
                    onChange={(event) => onFormChange(event)}
                    onBlur={(event) => onFormChange(event)}
                    autoComplete="email"
                  />
                  {formErrors.email && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.email}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={!!formErrors.confirmEmail}
                    required
                    value={formData.confirmEmail}
                    fullWidth
                    label="Confirmez votre courriel"
                    name="confirmEmail"
                    onChange={(event) => onFormChange(event)}
                    onBlur={(event) => onFormChange(event)}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }} onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  />
                  {formErrors.confirmEmail && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.confirmEmail}
                    </p>
                  )}
                </Grid>
                {userType !== 'Candidate' && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="enterpriseName"
                      error={!!formErrors.enterpriseName}
                      value={formData.enterpriseName || ''}
                      label="Entreprise"
                      id="entreprise"
                      autoComplete="company"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                    />
                    {formErrors.enterpriseName && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.enterpriseName}
                      </p>
                    )}
                  </Grid>
                )}
                <PasswordCreation
                  errors={formErrors}
                  setErrors={setFormErrors}
                  formData={formData}
                  onFormChange={onFormChange}
                />
                {/* {userType === 'User' && ( */}
                <ProfileImageStep
                  userType={userType}
                  image={image}
                  setImage={setImage}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  formData={formData}
                  onFormChange={onFormChange}
                />
                {/* )} */}
                {userType !== 'Candidate' && (
                  <WebsiteStep
                    website={website}
                    errors={formErrors}
                    setErrors={setFormErrors}
                    formData={formData}
                    onFormChange={onFormChange}
                  />
                )}
                {userType === 'Candidate' && (
                  <UploadCV
                    userType={userType}
                    curriculum={curriculum}
                    setCurriculum={setCurriculum}
                    errors={formErrors}
                    setErrors={setFormErrors}
                    formData={formData}
                    onFormChange={onFormChange}
                  />
                )}
                <LocationStep
                  isGlobalChecked={isGlobal}
                  onGlobalChange={() => setIsGlobal(!isGlobal)}
                  userType={userType}
                  getOptionLabel={getLocationDisplayName}
                  handleLocationSelection={handleLivingLocationSelection}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  formData={formData}
                  onFormChange={onFormChange}
                />
                <LanguageStep
                  userType={userType}
                  handleLanguagesSelection={handleLanguages}
                  languages={languages}
                  setLanguages={setLanguages}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  formData={formData}
                  onFormChange={onFormChange}
                />
                {userType !== 'Partner' && (
                  <JobsStep
                    userType={userType}
                    errors={formErrors}
                    setErrors={setFormErrors}
                    formData={formData}
                    onFormChange={onFormChange}
                  />
                )}
                {userType === 'Candidate' && (
                  <DestinationsStep
                    errors={formErrors}
                    setErrors={setFormErrors}
                    formData={formData}
                    onFormChange={onFormChange}
                  />
                )}
                {userType === 'Partner' && (
                  <ServiceStep
                    services={services}
                    setServices={setServices}
                    errors={formErrors}
                    setErrors={setFormErrors}
                    formData={formData}
                    onFormChange={onFormChange}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isEmailOptIn}
                      onChange={() => setEmailOptIn(!isEmailOptIn)}
                      name="email-opt-in"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  )}
                  label="J’accepte d'être informé par courriel de l'actualité de moovejob"
                  className={classes.emailCheck}
                />
              </Grid>
              {validateConsent()}
              {recaptchaResponse !== -1 && (
                <ReCaptcha handleRecaptchaResponse={handleRecaptchaResponse}/>
              )}

              <Button
                onClick={handleSubmit}
                disabled={false}
                className={classes.submit}
              >
                Valider
              </Button>
              <Typography variant="h2" className={classes.slogan} color='secondary' >
                    Ouvrez-vous au monde!
              </Typography>
            </form>
          </Box>
        </Container>
        <Footer/>
      </div>
    </main>
  );
};

export default BaseForm;
