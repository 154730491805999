import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/Logo-Moovejob.png';
import connexion from '../../images/connection_background.jpg';
import theme from '../theme';
import Seo from '../UI-Component/Seo';

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 'calc(100vh - 20px)',
    position: 'relative',
    top: '15vh',
    paddingBottom: '50px',
    '& h1': {
      marginBottom: '3vh',
    },
    '& p': {
      lineHeight: '2',
      fontSize: '1.3rem',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5',
      },
    },
  },
  flexHeightDesktop: {
    height: 'auto',
    justifyContent: 'center',
    padding: '10vh 0',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  flexAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  bgHero: {
    position: 'relative',
    backgroundImage: `url(${connexion})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    backgroundPosition: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255,255,255, 0.7)',
    },
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
  },

  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoStyle: {
    maxWidth: '200px',
    margin: '0 auto',
  },
  logoStyleSmall: {
    maxWidth: '150px',
  },

  subTitle: {
    fontWeight: 'bold',
    margin: '3vh 0 6vh',
    textAlign: 'center',
    width: '100%',
  },
  linkStyle: {
    cursor: 'pointer',
  },
  buttonStyle: {
    margin: '2vh 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  html: {
    height: '100%',
    margin: 0,
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
}));

const Confirmation = (props) => {
  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }

  const userData = props.location.state;
  const classes = useStyles(theme);

  if (!userData) {
    return (
      <main>
        <Seo name="robots" robot="noindex" />
        <div className={classes.bgHero}>
          <Container maxWidth={false} className={classes.homeContainer}>
            <Box>
              <Typography variant="h1" color="secondary">
                Un problème est survenu... veuillez ré-essayer.
              </Typography>
              <Typography variant="body2">
                En vous souhaitant une bonne recherche d'emploi,
              </Typography>
              <Typography variant="body2" className={classes.alignCenter}>
                l'équipe de
                <img src={logo} alt="logo" className={classes.logoStyleSmall} />
              </Typography>
            </Box>
          </Container>
        </div>
      </main>
    );
  }

  return (
    <main>
      <Seo name="robots" robot="noindex" />
      <div className={classes.bgHero}>
        <Container maxWidth={false} className={classes.homeContainer}>
          <Box>
            <Typography variant="h1" color="secondary">
              Bonjour {userData.first_name}
            </Typography>
            <Typography variant="body2">
              Nous vous avons envoyé un courriel de confirmation à l'adresse{' '}
              {userData.email}.
            </Typography>
            <Typography variant="body2">
              Veuillez confirmer votre adresse pour compléter votre inscription
              sur
            </Typography>
            <img src={logo} alt="logo" className={classes.logoStyle} />

            <Typography variant="body2">
              Vos données sont en sécurité avec nous.
            </Typography>
            <Typography variant="body2">
              Vous pouvez vérifier notre{' '}
              <Link
                onClick={() => navigate('/politique-de-confidentialite')}
                className={classes.linkStyle}
              >
                <strong>politique de confidentialité.</strong>
              </Link>
            </Typography>
            <Typography variant="body2">
              En vous souhaitant une bonne recherche d'emploi,
            </Typography>
            <Typography variant="body2" className={classes.alignCenter}>
              l'équipe de{' '}
              <img src={logo} alt="logo" className={classes.logoStyleSmall} />
            </Typography>
          </Box>
        </Container>
      </div>
    </main>
  );
};

export default Confirmation;
