import React, { useState,useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Button,
  Link,
  Tooltip,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Footer from '../UI-Component/Footer';
import CheckIcon from '@material-ui/icons/Check';
import useSessionContext from '../sessions/useSessionContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: '#f0f4f8'
  },
  priceContainer:{
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    justifyContent: 'space-evenly',
    paddingBottom: '50px',
  },
  container:{
    marginTop: "40px",
    justifyContent: 'center'
  },
  pricingItem:{
    border: "2px dashed #e5087e",
    borderRadius: "20px",
    padding: '10px',
  },
  pricingHeader: {
    width: '100%',
    borderRadius: '20px',
    padding: "5px",
    backgroundColor: 'white'
  },
  pricingDescription: {
    borderRadius: '20px',
    marginTop: '15px',
    paddingBottom: '15px',
    backgroundColor: 'white'
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: '30px',
    textAlign: 'center',
    '& .MuiListItemAvatar-root':{
      minWidth: '30px'
    },
    '& .MuiListItemAvatar-root':{
      minWidth: '40px'
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      padding: '0px!important',
      '& .MuiListItemAvatar-root':{
        marginTop: '12px'
      }
    },
  },
  listItemText:{
    '& .MuiListItemText-primary':{
      fontSize: "1em",
      color: "#555e68"
    }
  },
  iconStyle:{
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    "& .MuiAvatar-img":{
      height: "unset"
    }
  },
  pricingAmount:{
    textAlign: "left",
    margin: "0",
    paddingLeft: "16px",
    fontWeight: "700",
    fontSize: "2.5em"
  },
  pricingAmountDesc: {
    color: "#636b74",
    fontSize: "1.2rem"
  },
  pricingTitle:{
    textAlign: 'left',
    paddingLeft: "16px",
    color: "#636b74",
    fontSize: '0.9em',
    fontWeight: '600'
  },
  listOffer:{
    margin: '0px 15px',
    '& li:not(:last-child)':{
      borderBottom: '1px solid #eee'
    }
  },
  selectButton: {
    height: '44px',
    color: 'white',
    borderRadius: '30px',
    margin: 0,
    padding: '15px',
    minWidth: 'unset',
    width: 'auto',
    fontWeight: 700,
    fontSize: '15px',
  },
  offerName:{
    textAlign: 'left'
  },
  remarks: {
    textAlign: 'left',
    fontSize: '1em'
  },
  tooltip:{
    fontSize: "1em"
  }
}));

const Pricing = (props) => {
  const { type } = props;
  const classes = useStyles(theme);
  const [products, setProducts] = useState([]);
  const { user } = useSessionContext();
  const history = useHistory();


  const getData = () => fetch('/products/'+type).then((res) => res.json());

  useEffect(() => {
    getData()
      .then((data) => {
        setProducts(data)
      });
  }, []);

  const changePage = (new_page) => {
    history.push(new_page);
  };

  return (
    <main className={classes.root}>
        <Container maxWidth="lg" className={classes.priceContainer}>
          <Box>
            { type == "Enterprise" ? (
              <Typography variant="h1" color="secondary" className={classes.subTitle}>
                Nos forfaits pour les entreprises
              </Typography>
            ) : (
              <Typography variant="h1" color="secondary" className={classes.subTitle}>
                Nos forfaits pour les partenaires
              </Typography>
            )}
            <Typography variant="body2" className={classes.subTitle}>
              Sélectionnez l'abonnement adapté à votre besoin
            </Typography>
              <Grid container spacing={2} className={classes.container}>
                  {products.length> 0 && (
                    type == 'Enterprise' && (
                      <Grid item lg={3} xs={12} md={4}  >
                        <Grid className={classes.pricingItem}>
                          <div className={classes.pricingHeader}>
                            <List>
                              <ListItem className={classes.listItemIcon}>
                                  
                                  <ListItemText
                                    className={classes.offerName}
                                    primary={
                                      <React.Fragment>
                                        <Chip label="GRATUIT" color='secondary' />
                                      </React.Fragment>
                                    }
                                  />
                              </ListItem>
                            </List>
                            <Typography variant="h3" color='secondary' className={classes.pricingAmount}>
                                0 $
                            </Typography>
                            <Typography variant="body2" color='secondary' className={classes.pricingTitle}>
                              Parfait pour tester avant de vous engager!
                            </Typography>
                            
                          </div>
                          <div className={classes.pricingDescription}>
                            <List dense={true} className={classes.listOffer}>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="5 MÉTIERS"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Matchs illimités"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Visibilité du nombre de candidats potentiel"
                                  />
                                </ListItem>
                            </List>
                            {!user && (
                                <Button onClick={ () => changePage('/entreprise') }  color="secondary" className={classes.selectButton} size="small">
                                    COMMENCER
                                </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )
                  )}
                  {products.map((product) => (
                    <Grid item lg={3} xs={12} md={4}  >
                      <Grid className={classes.pricingItem}>
                        <div className={classes.pricingHeader}>
                          <List>
                            <ListItem className={classes.listItemIcon}>
                                <ListItemText
                                  className={classes.offerName}
                                  primary={
                                    <React.Fragment>
                                      <Chip label={product.name.toUpperCase()}  color='secondary'/>
                                    </React.Fragment>
                                  }
                                />
                            </ListItem>
                          </List>
                          <Typography variant="h3" color='secondary' className={classes.pricingAmount}>
                              {product.price_cents/100} $  <span className={classes.pricingAmountDesc}>/ {product.duration} mois</span>
                          </Typography>
                          <Typography variant="body2" color='secondary' className={classes.pricingTitle}>
                            soit {product.monthly_price} $ / mois
                          </Typography>
                        </div>
                        <div className={classes.pricingDescription}>
                          <List dense={true} className={classes.listOffer}>
                              {product.stripe_product_options
                              .map((option) => (
                                  <ListItem>
                                    <ListItemIcon>
                                      <CheckIcon color='secondary'/>
                                    </ListItemIcon>
                                    {option.name=="minimum_guaranteed_connexion" ? (
                                      <Tooltip classes={{ tooltip: classes.tooltip }} title="Sinon prolongation gratuite de la durée de l'offre initiale" placement="top-start">
                                        <ListItemText
                                          className={classes.listItemText}
                                          primary={option.title}
                                        />
                                      </Tooltip>
                                    )
                                    :(
                                      <ListItemText
                                        className={classes.listItemText}
                                        primary={option.title}
                                      />
                                    )}
                                  </ListItem>
                              ))}
                          </List>
                          {user && user.type_of_user==type && !user.is_subscribed && (
                            <Button onClick={()=>changePage('/paiement/'+product.product_id)}  color="secondary" className={classes.selectButton} size="small">
                              S'ABONNER
                            </Button>
                          )}
                          {!user && type=='Enterprise' &&(
                              <Button onClick={ () => changePage('/entreprise') } color="secondary" className={classes.selectButton} size="small">
                                  COMMENCER
                              </Button>
                          )}
                          {!user && type=='Partner'&& (
                              <Button onClick={ () => changePage('/partenaire') } color="secondary" className={classes.selectButton} size="small">
                                  COMMENCER
                              </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
          </Box>
        </Container>
      <Footer />
    </main>
  );
};

export default Pricing;
