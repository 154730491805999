import React, {useEffect} from 'react';
import {
  AppBar, Grid, Card, Badge, makeStyles, Typography
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import UserProfile from './UserProfile';
import Notifications from './Notifications';
import Settings from './Settings';
import useSessionContext from '../../sessions/useSessionContext';
import Subscription from './Subscriptions';
import HelpSection from './HelpSection';
import Messages from './Messages';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    top:"11px!important",
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'initial!important',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      position: 'sticky!important',
      top: 200,
    },
    '& p': {
      fontSize: '16px',
      textAlign: 'left',
      fontWeight: 500,
    },
    '&.Mui-selected': {},
  },
  menuTabsProfile: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('sm')]: {
      top: '100px',
    },
    '& button': {
      justifyContent: 'flex-start',
      margin: 0,
      minWidth: 'fit-content',
      maxWidth: '100%',
      borderBottomLeftRadius: 30,
      borderTopLeftRadius: 30,
      borderBottom: '1px solid #fff',
      borderRight: '2px solid #e5087e',
      backgroundColor: 'transparent',
      color: '#37383e',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '1px 1px 1px 0 rgba(0, 0, 0, 0.1)',
      },
      '&[aria-selected=true]': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'inset 2px 2px 2px 0 rgba(0, 0, 0, 0.2)',
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },

      '& span': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textTransform: 'none',
        fontSize: 16,
        '& span': {
          flexDirection: 'row',
          justifyContent: 'center',
          fontSize: 12,
          minWidth: 15,
          height: 17,
        },
      },
    },

    '& svg': {
      fill: theme.palette.secondary.main,
      marginBottom: '0!important',
    },
  },
  menuTabs: {
    '& button': {
      fontSize: 16,
      margin: 0,
      minWidth: 'fit-content',

      '& span': {
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  },
  iconContainer: {
    marginRight: '10px',
    marginBottom: '0!important',
    display: 'flex',
    alignItems: 'center',
  },
  notificationCircle: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50px',
    width: 15,
    height: 15,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  notificationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge:{
    border: 0,
    fontSize: '15px!important',
  }
}));

export default function ScrollableTabsButtonPrevent(props) {
  const classes = useStyles();
  const { navId, setNavId } = useSessionContext();
  const { activePage } = props;

  const handleChange = function (event, newValue) {
    setNavId(newValue);
  };

  useEffect(() => {
    if (typeof activePage !== 'undefined' && activePage !== null) {
      switch(activePage) {
        case 'profil':
          setNavId(0);
          break;
        case 'notifications':
          setNavId(1);
          break;
        case 'messages':
          setNavId(2);
          break;
        case 'aide':
          setNavId(3);
          break;
        case 'parametre':
          setNavId(4);
          break;
        case 'abonnements':
          setNavId(5);
          break;
        default:
          setNavId(0);
      }
    }
  }, [activePage, setNavId]);

  const { user } = useSessionContext();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={3} md={3} lg={3}>
          <AppBar
            position="relative"
            className={classes.menuTabsProfile}
            square
          >
            <Card style={{ padding: 20 }} square>
              <Typography variant="h3" >
                <b>Bienvenue</b>
              </Typography>
              <Typography variant="h3">
                {user.full_name ? user.full_name : 'FULL_NAME LOAD ERROR'}
              </Typography>
            </Card>

            <Tabs
              value={navId}
              onChange={handleChange}
              orientation="vertical"
              variant="scrollable"
              scrollButtons="off"
              aria-label="scrollable prevent tabs example"
            >
              <Tab
                icon={(
                  <div className={classes.iconContainer}>
                    <AccountCircleIcon />
                  </div>
                )}
                aria-label="Profil"
                label="Profil"
                {...a11yProps(0)}
              />
              <Tab
                className="notifications"
                icon={(
                  <div className={classes.iconContainer}>
                    <NotificationsIcon />
                  </div>
                )}
                aria-label="Notifications"
                label={
                  <>
                    {user.unread_notif > 0 ? (
                      <Badge badgeContent={user.unread_notif} color="secondary" className={classes.badge}>
                        Notifications
                     </Badge>
                    ) : (
                      <div className={classes.notificationWrapper}>
                        Notifications
                      </div>
                    )}
                  </>
                }
                {...a11yProps(1)}
              />
              <Tab
                className="messages"
                icon={(
                  <div className={classes.iconContainer}>
                    <EmailIcon />
                  </div>
                )}
                aria-label="Messages"
                label={
                  <>
                    {user.unread_message > 0 ? (
                      <Badge badgeContent={user.unread_message} color="secondary" className={classes.badge}>
                        Messages
                     </Badge>
                    ) : (
                      <div className={classes.notificationWrapper}>
                        Messages
                      </div>
                    )}
                  </>
                }
                {...a11yProps(2)}
              />
              <Tab
                icon={(
                  <div className={classes.iconContainer}>
                    <HelpIcon />
                  </div>
                )}
                aria-label="Aide"
                label="Aide"
                {...a11yProps(3)}
              />
              <Tab
                // tab-index={showTab ? open === -1 : open === 0}
                // aria-selected={activateBtn ? open === false : open === true}
                icon={(
                  <div className={classes.iconContainer} id="subscriptionTab">
                    <SettingsIcon />
                  </div>
                )}
                aria-label="Paramètres"
                label="Paramètres"
                {...a11yProps(4)}
              />
              { user.type_of_user != 'Candidate' && user.is_subscribed && <Tab
                // tab-index={showTab ? open === -1 : open === 0}
                // aria-selected={activateBtn ? open === false : open === true}
                icon={(
                  <div className={classes.iconContainer} id="subscriptionTab">
                    <AttachMoneyIcon />
                  </div>
                )}
                aria-label="Abonnements"
                label="Abonnements"
                {...a11yProps(5)}
              />}
          </Tabs>
          </AppBar>
        </Grid>

        <Grid item lg={9} md={9} sm={9}>
          <AppBar position="relative" className={classes.menuTabs}>
            <TabPanel value={navId} index={0}>
              <UserProfile />
            </TabPanel>
            <TabPanel value={navId} index={1}>
              <Notifications />
            </TabPanel>
            <TabPanel value={navId} index={2}>
              <Messages />
            </TabPanel>
            <TabPanel value={navId} index={3}>
              <HelpSection/>
            </TabPanel>
            <TabPanel value={navId} index={4}>
              <Settings />
            </TabPanel>
            <TabPanel value={navId} index={5}>
              <Subscription />
            </TabPanel>
          </AppBar>
        </Grid>
      </Grid>
    </div>
  );
}
